import { useEffect, useState } from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import MyCheckBox from "../MyCheckBox";
import {doGet, doPost, doPostBody} from "../../services/rest";
import {useUserPreferences} from "../context/UserPreferencesProvider";


function MySettings() {
    const [isLoading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const [saveStatus, setSaveStatus] = useState('');
    const [legacyView, setLegacyView] = useState(false);
    const [showMarketplaceEmailSetting, setShowMarketplaceEmailSetting] = useState(true);
    const { updateLegacyViewSetting } = useUserPreferences();

    useEffect(() => {
        doGet('my-settings').then((response) => {
            setSettings(response.settings);
            setShowMarketplaceEmailSetting(!response.azure);
            setLegacyView(response.legacyView)
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching settings:', error);
            setLoading(false);
        });
    }, []);

    const handleSettingChange = (settingName) => (event) => {
        const newSettings = {
            ...settings,
            [settingName]: event.target.checked
        };
        setSettings(newSettings);
    };

    const handleSave = async () => {
        setLoading(true);
        const resp = await doPostBody('my-settings/save-settings', settings);
        if(resp.id){
            setSaveStatus("Your notification settings have been updated.")
            setLoading(false);
        }else{
            setSaveStatus("An Error occurred. Please try again.")
            setLoading(false);
        }

    }

    const saveLegacyView = async () => {
       if( window.confirm("You are about to change you view settings.  This will change you settings for all of your devices.  You may need to refresh your other devices for them to see the change right away.")){
            setLoading(true);
            const formData = new FormData();
            formData.append("legacySetting", legacyView);
            const resp = await doPost(`my-settings/legacy-setting`, formData);
            setLegacyView(resp.isLegacyView);
            updateLegacyViewSetting(resp.isLegacyView);
            setLoading(false);
       }
    }



    if (isLoading) {
        return (
            <>
                <Heading title={'My Settings'} />
                <MySpinner />
            </>
        );
    }

    return (
        <>
            <Heading title={'My Settings'} />
            <div className={'inner-container'} style={{ paddingTop: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <h2>Notification Settings</h2>
                    <h3>Questionnaire Notification Settings</h3>
                    <MyCheckBox
                        id="questionnaire-notifications"
                        name="questionnaireNotificationEnabled"
                        value="true"
                        checked={settings.questionnaireNotificationEnabled}
                        onChange={handleSettingChange('questionnaireNotificationEnabled')}
                        label="Enable Questionnaire Notifications"
                        color="#FFA500"
                    />
                    <h3>Marketplace Notification Settings</h3>
                    {showMarketplaceEmailSetting && <MyCheckBox
                        id="marketplace-email"
                        name="marketplaceEmailNotifications"
                        value="true"
                        checked={settings.marketplaceEmailNotifications}
                        onChange={handleSettingChange('marketplaceEmailNotifications')}
                        label="Receive Marketplace Email Notifications"
                        color="#FFA500"
                    /> }

                    <MyCheckBox
                        id="marketplace-push"
                        name="marketplacePushNotifications"
                        value="true"
                        checked={settings.marketplacePushNotifications}
                        onChange={handleSettingChange('marketplacePushNotifications')}
                        label="Receive Marketplace Push Notifications"
                        color="#FFA500"
                    />

                    <button onClick={handleSave} className={'submitPost'} style={{paddingTop: 10}}>Save</button>

                    {saveStatus && (
                        <div style={{
                            marginTop: '10px',
                            color: saveStatus.includes('Error') ? '#ff0000' : '#008000'
                        }}>
                            {saveStatus}
                        </div>
                    )}
                    <h2>User Settings</h2>
                    <MyCheckBox
                        id="legacy-view"
                        name="marketplacePushNotifications"
                        value="true"
                        checked={legacyView}
                        onChange={() => setLegacyView(!legacyView)}
                        label="Use Legacy View"
                        color="#FFA500"
                    />
                    <button onClick={saveLegacyView} className={'submitPost'} style={{paddingTop: 10}}>Save</button>
                </div>
            </div>
        </>
    );
}

export default MySettings;
