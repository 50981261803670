import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faEdit, faPerson} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import { doGet } from "../../services/rest";
import './MarketPlaceDetails.css';
import {serviceUrl} from "../../defaults/AppConfig";
import {auth} from "../../firebase/firebase";
import moment from "moment/moment";
function MarketPlaceDetail() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const { id } = useParams();
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        loadMarketplaceItem();
    }, [id]);

    const loadMarketplaceItem = () => {
        setLoading(true);
        doGet(`marketplace/item/${id}`).then(data => {
            setItem(data);
            setLoading(false);
            if(data.postedBy.id === auth.currentUser?.uid) {
                setShowEdit(true);
            }
        });
    };

    const handleNextImage = () => {
        if (item?.images?.length) {
            setCurrentImageIndex((prev) => (prev + 1) % item.images.length);
        }
    };

    const handlePrevImage = () => {
        if (item?.images?.length) {
            setCurrentImageIndex((prev) => (prev - 1 + item.images.length) % item.images.length);
        }
    };

    if (loading || !item) {
        return (
            <>
                <Heading title={'Marketplace Item'} />
                <MySpinner />
            </>
        );
    }

    const formatDate = (dateString) => {
        return moment(dateString).format('MMMM D, YYYY');
    };

    return (
        <>
            <Heading title={item.title} icons={showEdit ? [<FontAwesomeIcon icon={faEdit} cursor={'pointer'} onClick={() => navigate(`/marketplace/edit/${id}`)} /> ] : []} />
            <div className="marketplace-detail">
                <div className="image-carousel">
                    {item.images && item.images.length > 0 && (
                        <div className="image-container-marketplace">
                            <img
                                src={serviceUrl+`marketplace/images/full/${item.images[currentImageIndex]}`}
                                alt={item.title}
                                className="main-image"
                            />
                            {item.images.length > 1 && (
                                <>
                                    <button
                                        onClick={handlePrevImage}
                                        className="nav-button prev-button"
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    <button
                                        onClick={handleNextImage}
                                        className="nav-button next-button"
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                </>
                            )}
                        </div>
                    )}

                    {/* Thumbnail strip */}
                    {item.images && item.images.length > 1 && (
                        <div className="thumbnail-strip">
                            {item.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={serviceUrl+`marketplace/images/thumbnail/${image}`}
                                    alt={`${item.title} thumbnail ${index + 1}`}
                                    className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                                    onClick={() => setCurrentImageIndex(index)}
                                />
                            ))}
                        </div>
                    )}
                </div>

                <div className="item-details">
                    <h1 className="title">{item.title}</h1>
                    <p className="price">${item.price.toLocaleString('en-US', {minimumFractionDigits: 2})}</p>

                    <div className="posted-date">
                        Posted on {formatDate(item.postedDate)}
                    </div>

                    <div className="description">
                        {item.description}
                    </div>

                    <div className="contact-info">
                        <h2>Contact Information</h2>
                        <p className="contact-row">
                            <span><FontAwesomeIcon size={"xl"} icon={faPerson} /></span>
                            <span style={{paddingLeft: 5}}>{item.postedBy.displayName}</span>
                        </p>
                        {item.contactPhoneNumber && (
                            <p className="contact-row">
                                <span>📞</span>
                                <span>{item.contactPhoneNumber}</span>
                            </p>
                        )}
                        {item.contactEmail && (
                            <p className="contact-row">
                                <span>✉️</span>
                                <span>{item.contactEmail}</span>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MarketPlaceDetail;
