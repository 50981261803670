import TeamMemberImages from "./TeamMemberImages";
import {defaultColor} from "../../defaults/AppConfig";

function ViewEmployee({employee}) {
    return (
        <div style={style.container}>
            <div style={style.profileHeader}>
                <div style={style.imageWrapper}>
                    <TeamMemberImages teamMember={employee} />
                </div>
                <div style={style.basicInfo}>
                    <h2 style={style.name}>{employee.fname} {employee.lname}</h2>
                    <div style={style.position}>{employee.position}</div>
                    <div style={style.department}>{employee.teamDepartments.name}</div>
                </div>
            </div>

            <div style={style.detailsContainer}>
                <h3 style={style.sectionTitle}>Contact Information</h3>

                <div style={style.detailsGrid}>
                    {employee.phone && (
                        <div style={style.detailItem}>
                            <div style={style.detailLabel}>Phone</div>
                            <div style={style.detailValue}>
                                <a style={style.linkStyle} href={`tel:${employee.phone}`}>
                                    {employee.phone} {employee.extension && `Ext. ${employee.extension}`}
                                </a>
                            </div>
                        </div>
                    )}

                    {employee.mobile && (
                        <div style={style.detailItem}>
                            <div style={style.detailLabel}>Cell</div>
                            <div style={style.detailValue}>
                                <a style={style.linkStyle} href={`tel:${employee.mobile}`}>
                                    {employee.mobile}
                                </a>
                            </div>
                        </div>
                    )}

                    {employee.email && (
                        <div style={style.detailItem}>
                            <div style={style.detailLabel}>Email</div>
                            <div style={style.detailValue}>
                                <a style={style.linkStyle} href={`mailto:${employee.email}`}>
                                    {employee.email}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const style = {
    container: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        maxWidth: '600px',
        margin: '0 auto',
        width: '100%',
        boxSizing: 'border-box',
    },
    profileHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '25px',
        flexWrap: 'wrap',
        borderBottom: '1px solid #f0f0f0',
        paddingBottom: '20px',
    },
    imageWrapper: {
        marginRight: '25px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'center',
    },
    basicInfo: {
        flex: 1,
        minWidth: '200px',
    },
    name: {
        color: defaultColor,
        fontWeight: 'bold',
        fontSize: '28px',
        margin: '0 0 10px 0',
    },
    position: {
        fontSize: '18px',
        color: '#555',
        marginBottom: '5px',
    },
    department: {
        fontSize: '16px',
        color: defaultColor,
        fontWeight: '500',
    },
    detailsContainer: {
        width: '100%',
    },
    sectionTitle: {
        fontSize: '18px',
        color: '#333',
        marginBottom: '15px',
        paddingBottom: '8px',
        borderBottom: '2px solid #f0f0f0',
        position: 'relative',
    },
    detailsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '15px',
    },
    detailItem: {
        marginBottom: '15px',
    },
    detailLabel: {
        fontWeight: 'bold',
        fontSize: '16px',
        marginBottom: '5px',
        color: '#555',
    },
    detailValue: {
        fontSize: '16px',
        color: '#333',
    },
    linkStyle: {
        textDecoration: 'none',
        color: defaultColor,
        transition: 'color 0.2s ease',
        borderBottom: '1px dashed #f0f0f0',
        paddingBottom: '2px',
    },

    // Preserving original styles that might be used elsewhere
    divPadding: {
        paddingTop: '10px',
    },
    defaultContainer: {
        alignItems: 'center',
    },
    imageContainer: {
        width: '80%',
        flexDirection: 'row',
    },
    informationContainer: {
        width: '80%',
        paddingTop: 10,
        textAlign: 'left'
    },
    multiText: {
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
    },
    textBold: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textNotBold: {
        fontSize: 16,
    },
    elopementAlertContainer: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    paddingTop: {
        paddingTop: 10,
    }
};

export default ViewEmployee;
