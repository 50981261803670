import Heading from "../Heading";
import React, {useCallback, useEffect, useMemo} from "react";
import { useForm, Controller } from "react-hook-form";
import {TextField, Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {defaultColor, serviceUrl} from "../../defaults/AppConfig";
import {useDropzone} from "react-dropzone";
import {auth} from "../../firebase/firebase";
import {doGet, doPostBody, uploadPostImages} from "../../services/rest";
import {useNavigate, useParams} from "react-router-dom";
import MySpinner from "../MySpinner";
import moment from "moment";
import Cookies from "universal-cookie";
import {useAuthState} from "react-firebase-hooks/auth";
function MarketPlaceForm(){
    const {id} = useParams();
    const cookies = new Cookies(null, {path: '/'})
    const navigation = useNavigate();
    const [showImageUpload, setShowImageUpload] = React.useState(false);
    const [appLocation, setAppLocation] = React.useState([]);
    const [selectedLocations, setSelectedLocations] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const [user] = useAuthState(auth);
    const [imageUploadLoading, setImageUploadLoading] = React.useState(false);
    const { handleSubmit, control, register, setValue, getValues, reset } = useForm({
        defaultValues: {
            title: "",
            description: "",
            price: "",
            contactPhoneNumber: "",
            contactEmail: "",
            deleted: false,
            images: [],
            appLocations: []
        }
    });

    const renewItem = async event =>{
        event.preventDefault();
        setLoading(true);
        const resp = await doGet(`marketplace/renew?itemId=${getValues("id")}`);
        if(resp.id){
            reset(resp);
            setLoading(false);
        }
    }

    const saveLastSelectedLocation = (locations) =>{
        if(!user) return;
        try{
            cookies.set(`last_selected_location_${user.uid}`, JSON.stringify(locations));
        }catch(e){
            console.error(e);
        }
    }
    useEffect(()=>{
        if(appLocation && !id){
            getLastSelectedLocation();
        }
    }, [appLocation, id])

    const getLastSelectedLocation = () =>{
        if(!user) return;
        try{
            const lastSelectectedLocation = cookies.get(`last_selected_location_${user.uid}`);
            console.log(lastSelectectedLocation);
            if(lastSelectectedLocation){
                const locations = lastSelectectedLocation;
                const filterLocations = locations.filter(loc => appLocation.some(appLoc => appLoc.id === loc.id));
                setSelectedLocations(filterLocations);
                setValue('appLocation', filterLocations);
            }
        }catch(e){
            console.error(e);
        }
    }

    const updateContactInfo = (contactInfo) =>{
        if(!user) return;
        try{
            cookies.set(`contact_info_${user.uid}`, JSON.stringify(contactInfo));
        }catch(e){
            console.error(e);
        }
    }

    const getContactInfo = () =>{
        if(!user) return;
        try{
            const saveContacts = cookies.get(`contact_info_${user.uid}`);
            if(saveContacts){
                const info = saveContacts;
                setValue('contactPhoneNumber', info.contactPhoneNumber);
                setValue('contactEmail', info.contactEmail);
            }
        }catch(e){
            console.error(e);
        }
    }

    const getStatus = () => {
        if(getValues('deleted')){
            return "Your item has been marked as removed, please uncheck 'removed' if you want it to display again.";
        }
        if(moment().diff(moment(getValues('postedDate')), 'days') > 40){
            return <>Your item has expired.  Click <a onClick={renewItem} href='#'>here</a> to make it active for another 40 days.</>;
        }
        if(moment().diff(moment(getValues('postedDate')), 'days') > 35){
            return <>You item is going to expire soon, click <a onClick={renewItem} href='#'>here</a> to keep it active for another 40 days</>;
        }

        return "Your item is active";
    }

    useEffect(() => {
            doGet('stream/listeners').then(listeners => setAppLocation(listeners));
    },[])

    useEffect(() => {
        const loadItem = async () => {
                setLoading(true);
                const item = await doGet(`marketplace/item/${id}`);
                if(item.postedBy.id === auth.currentUser?.uid){
                    reset(item);
                    setSelectedLocations(item.appLocations);
                    setLoading(false);
                }else{
                    navigation((`/marketplace/${id}`));
                }
        }
        if(id){
            loadItem();
        }else{
            getContactInfo();
        }
    }, [id])

    const onAddPhotoSubmit = (e) => {
        e.preventDefault();
        setShowImageUpload(true);
    }

    const onSubmit = async (data) => {
        if(data.images.length === 0){
            window.alert("please add at least one image of your item to continue.")
            return;
        }
        if(data.appLocations.length === 0){
            window.alert("please add at least one app location to continue.");
            return;
        }
        const resp = await doPostBody('marketplace/save', data);
        if(resp.id){
            updateContactInfo({contactPhoneNumber: data.contactPhoneNumber, contactEmail: data.contactEmail});
            saveLastSelectedLocation(data.appLocations);
            navigation("/marketplace")
        }else{
            window.alert("Something has gone wrong, you item did not post.");
        }
    };
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        margin: '0 auto',
        transition: 'border .24s ease-in-out'
    };
    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };
    const handleLocationChange = (location) => (event) => {
        const currentLocations = getValues('appLocations');
        let newLocations;
            // Add location if it's not already in the array
            if (!currentLocations.some(loc => loc.id === location.id)) {
                newLocations = [...currentLocations, location];
            } else {
                // Remove location if it exists in the array
                newLocations = currentLocations.filter(loc => loc.id !== location.id);
            }
        setSelectedLocations(newLocations);
        setValue('appLocations', newLocations);
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    const onDrop = useCallback(acceptedFiles => {
        const validFiles = acceptedFiles.filter((file) => {
            if (!file.type.startsWith('image/')) {
                window.alert(`${file.name} is not a valid image.`);
                return false;
            }
            if (file.size > 2 * 1024 * 1024) { // 2MB in bytes
                window.alert(`${file.name} exceeds the 2MB size limit.`);
                return false;
            }
            return true;
        });

        if(validFiles.length){
            setImageUploadLoading(true);
            const formData = new FormData()
            validFiles.forEach((file) => {
                formData.append("files", file);
            });
            uploadPostImages(formData).then(images =>{
                const currentImages = getValues("images") || [];
                const updatedImages = [...currentImages, ...images];
                setValue("images", updatedImages);
                setImages(updatedImages);
            }).finally(() =>{
                setImageUploadLoading(false);
            })
        }

    }, [])
    const {getRootProps, getInputProps, isDragActive,isFocused,
        isDragAccept,
        isDragReject} = useDropzone({onDrop})

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    if(loading){
        return (<>
            <Heading />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title={'New Marketplace Item'}/>
        <div className="inner-container" style={{paddingTop: 50}}>
            <div style={{display: "flex"}}>
                {getValues("images").map((image, index) => (
                    <div key={index} style={{
                        position: 'relative',
                        margin: '10px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        overflow: 'hidden'
                    }}>
                        <div
                            onClick={() => {
                                const currentImages = getValues("images");
                                const newImages = currentImages.filter((_, i) => i !== index);
                                setValue("images", newImages, { shouldDirty: true });
                                // Force re-render
                                setImages(newImages);
                                //setShowImageUpload(prev => !prev);
                            }}
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'rgba(255,255,255,0.8)',
                                borderRadius: '50%',
                                width: '24px',
                                height: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                zIndex: 1
                            }}
                        >
                            ✕
                        </div>
                        <img
                            src={serviceUrl + `marketplace/images/thumbnail/${image}`}
                            alt='Marketplace item'
                            style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                ))}
            </div>
            {showImageUpload ? <>
                <div {...getRootProps()}>
                    <input {...getInputProps({style})} />
                    {imageUploadLoading ? (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <p>Uploading images...</p>
                        </div>
                    ) : (
                        isDragActive ?
                            <p>Drop files or folder here...</p> :
                            <p>Drag and drop your image files in the gray box above. Or select Choose Files</p>
                    )}
                </div>
            </> :<a onClick={onAddPhotoSubmit} href={'#'}>Upload Image(s)</a>}
            <form onSubmit={handleSubmit(onSubmit)} style={{maxWidth: "600px", margin: "auto", paddingTop: 50}}>
                <Grid container spacing={3}>
                {/* Title */}
                    <Grid item xs={12}>
                    <Controller
                        name="title"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Title"
                                fullWidth
                                required
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                    <Controller
                        name="description"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Description"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                {/* Price */}
                <Grid item xs={12}>
                    <Controller
                        name="price"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Price"
                                fullWidth
                                type="number"
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </Grid>

                {/* Contact Phone Number */}
                <Grid item xs={12}>
                    <Controller
                        name="contactPhoneNumber"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Contact Phone Number"
                                fullWidth
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </Grid>

                {/* Contact Email */}
                <Grid item xs={12}>
                    <Controller
                        name="contactEmail"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Contact Email"
                                fullWidth
                                type="email"
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </Grid>

                    <Grid item container xs={12} alignItems="flex-start">
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Controller
                                name="deleted"
                                control={control}
                                render={({field}) => (
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        onChange={(e) => setValue("deleted", e.target.checked)}
                                    />
                                )}
                            />
                        }
                        label="Remove"
                    />
                </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Post to Location
                            </Typography>
                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                {appLocation.map((location, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={selectedLocations.some(loc => loc.id === location.id)}
                                                onChange={handleLocationChange(location)}
                                            />
                                        }
                                        label={location.locationName}
                                    />
                                ))}
                            </div>
                        </Grid>
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                    {getValues("postedDate") && <><p><span>Posted On: </span><span>{moment(getValues("postedDate")).format("MM/DD/yyyy")}</span></p><p>{getStatus()}</p></>}
                    <Button type="submit" variant="contained" style={{ backgroundColor: defaultColor, color: "#fff" }} fullWidth>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
        </div>
    </>)
}

export default MarketPlaceForm;
