import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPost, doPostBody, imageUploadHandler} from "../../services/rest";
import ToggleSwitch from "../inputs/ToggleSwitch";
import {Editor} from "@tinymce/tinymce-react";
import BenefitImage from "./BenefitImage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import MyCheckBox from "../MyCheckBox";
import PopUp from "../PopUp";
import AddFeature from "./AddFeature";

function BenefitsForm() {
    const {id} = useParams()
    const location = useLocation()
    const {category} = location?.state || {}
    const benefitAdmin = hasAdmin('ROLE_BENEFITS')
    const navigate = useNavigate();
    const [benefits, setBenefits] = useState({});
    const [loading, setLoading] = useState(true);
    const [appLocations, setAppLocations] = useState([])
    const [showAddFeatures, setShowAddFeatures] = useState(false)
    const [file, setFile] = useState();
    useEffect(() => {
        if (!benefitAdmin) {
            navigate(`/`)
        }
        if(id){
            doGet(`benefits/benefit/${id}`).then(resp =>{
                setBenefits(resp);
                setLoading(false);
            })
        }else{
            if(category){
                setBenefits(prevState => ({
                    ...prevState,
                    isSubCategory: false,
                    category: category
                }))
                setLoading(false)
            }
        }
        doGet('benefits-admin/locations').then(resp => setAppLocations(resp))
    }, [benefitAdmin, navigate, id]);

    if(loading){
        return (<>
            <Heading title={'Edit Benefit'} />
            <MySpinner />
        </>)
    }

    const onChange = event =>{
        setBenefits(prevBenefits => ({
            ...prevBenefits,
            [event.target.name]: event.target.value
        }))
    }

    const toggle = () =>{
        setBenefits(prevBenefits => ({
            ...prevBenefits,
            show: !prevBenefits.show
        }))
    }

    const setIcon = (icon) =>{
        setBenefits(prevBenefits => ({
            ...prevBenefits,
            newIcon: icon
        }))
    }

    const onEditChange = (data) => {
        setBenefits(prevBenefits => ({
            ...prevBenefits,
            body: data
        }))
    }

    const isChecked = (itemId) =>{
        return benefits?.appLocations?.some(location => location.id === itemId)
    }

    const toggleCheckBox = (item) => {
        if(benefits?.appLocations?.some(location => location.id === item.id)){
            setBenefits(prevState => ({
                ...prevState,
                appLocations: benefits?.appLocations?.filter(location => location.id !== item.id)
            }))
        }else{
            setBenefits(prevState => ({
                ...prevState,
                appLocations: [...prevState?.appLocations ?? [], item]
            }))
        }
    }

    const save =  async () =>{
        if(!benefits.title){
            window.alert("Please enter a title")
            return
        }
        setLoading(true)
        const resp = await doPostBody('benefits-admin/save', benefits)
        setLoading(false)
        setBenefits(resp)
    }
    const addUpdateFeature = feature =>{
        setBenefits((prevState) => {
            let existingFeature = prevState?.featureList.find((fl) => fl.id === feature.id);

            if (existingFeature) {
                return {
                    ...prevState,
                    featureList: prevState?.featureList.map((fl) => (fl.id === feature.id ? feature : fl))
                };
            } else {
                return {
                    ...prevState,
                    featureList: [...prevState?.featureList ?? [], feature]
                };
            }
        });
        setShowAddFeatures(false)
    }

    const deleteFeatureImage = () =>{
        setBenefits(prevBenefits => ({
            ...prevBenefits,
            featureImage: undefined
        }))
    }

    const uploadFile = async () =>{
        if(!file){
            alert("Please select a file to continue")
            return
        }
        setLoading(true)
        const formData = new FormData();
        formData.append("file", file)
        const resp = await doPost('benefits-admin/upload-image', formData)
        if(resp.data){
            setBenefits(prevBenefits => ({
                ...prevBenefits,
                featureImage: resp.data
            }))
        }else{
            window.alert("something has gone wrong")
        }
        setLoading(false)
    }

    const deleteFeature = (index) =>{
        if(window.confirm("Are you sure you want to delete this?  You will still need to click save")){
            setBenefits(prevState => {
                return {
                    ...prevState,
                    featureList: prevState.featureList.filter((_, index2) => index2 !== index)
                }
            });
        }
    }

    const deleteBenefit = () => {
        if(id){
            if(window.confirm("Are you sure you want to delete this benefit?")){
                doGet(`benefits-admin/delete-benefit/${id}`).then(resp =>{
                    if(resp.toString().trim() === 'true'){
                        navigate(-2)
                    }else{
                        window.alert("Something has gone wrong, we could not delete the benefit.")
                    }
                })
            }
        }
    }

    return(<>
        <Heading title={'Edit Benefit'} icons={[<FontAwesomeIcon onClick={deleteBenefit} icon={faTrash} cursor={'pointer'} />]} />
        <div className={'inner-container'} style={{paddingTop: 50}}>
            <input className={'search-input'} placeholder={'Enter Event Title'} name={'title'} value={benefits.title} onChange={onChange} />
            <div className={'flex-space-between'} style={{paddingTop: 25, alignItems: 'center', paddingBottom: 25}}>
                <div style={{display: 'flex', alignItems: 'center'}}>Set Default Icon:&nbsp;
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>Hide&nbsp; <ToggleSwitch isToggled={benefits.show} toggle={toggle} />&nbsp; Show</div>
            </div>
            <Editor
                onEditorChange={(e) => onEditChange(e)}
                licenseKey={'gpl'}
                value={benefits.body}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                    ],
                    toolbar: 'undo redo | blocks | link table|' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | image | bullist numlist outdent indent | ' +
                        'removeformat | toggle',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    automatic_uploads: true,
                    images_upload_handler: imageUploadHandler,
                }}
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            />

        <div className={'flex'} style={{paddingTop: 15, alignItems: 'center'}}>
            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', flex: 1}}>
                <input type={'file'} style={{paddingBottom: 15}} onChange={(event) => setFile(event.target.files[0])}
                       accept="image/gif, image/jpeg"/>
                <button onClick={uploadFile} className={'submitPost'}>Update Feature image</button>
                { benefits.featureImage && <button onClick={deleteFeatureImage} style={{marginTop: 15}} className={'submitPost'}>Delete Feature Image</button>}
            </div>
            {benefits.featureImage &&
                <div style={{flex: 1}}><BenefitImage style={{maxWidth: '100%'}} image={benefits.featureImage}/> </div>}
        </div>
        <div className={'flex'} style={{paddingTop: 15}}>
            <div style={{flex: 1}}>
                <h4>Features</h4>
                {benefits?.featureList?.map((feature, index) => (
                    <div key={feature.id} style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <div style={{paddingBottom: 15, flex: 1}}>{feature.displayName}</div><FontAwesomeIcon style={{cursor: "pointer"}} icon={faEdit} /><FontAwesomeIcon onClick={() => deleteFeature(index)} style={{cursor: "pointer", paddingLeft: 10}} icon={faTrash} />
                    </div>
                ))}
                <button onClick={() => setShowAddFeatures(true)} className={ 'submitPost'}>Add Feature</button>
            </div>
            <div style={{flex: 1, paddingLeft: 15}}>
                <h4>Assigned Locations</h4>
                {appLocations.map(location => (<MyCheckBox key={location.id} id={location.id} label={location.locationName} checked={isChecked(location.id)} onChange={() => toggleCheckBox(location)} />))}
            </div>
        </div>
            <button onClick={save} style={{marginBottom: 50}} className={'submitPost'}>Save</button>
        </div>
        {showAddFeatures && <PopUp
            content={<AddFeature onClose={(feature) => addUpdateFeature(feature)} />}
            handleClose={() => setShowAddFeatures(false)}
            />}
    </>)

}

export default BenefitsForm
