import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";
import EmployeeSelfService from "./EmployeeSelfService";
import ResidentSelfService from "./ResidentSelfService";

function SelfService() {
    const [loading, setLoading] = useState(true);
    const [directoryType, setDirectoryType] = useState();
    const [directoryInfo, setDirectoryInfo] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        doGet("self-service").then((response) => {
            if(response.success){
                setError(false)
                setDirectoryInfo(response.data);
                setDirectoryType(response.type);
                setLoading(false);
            }else{
                setLoading(false);
                setError(true)
            }
        })
    }, [])

    if(loading){
        return <>
            <Heading title={'Self Service'} />
            <MySpinner />
        </>
    }
    if(error){
        return <>
            <Heading title={'Error'} />
            <div className={'inner-container'}>
                <div className="error-container">
                    <div className="error-icon">
                        <i className="fas fa-exclamation-circle"></i>
                    </div>
                    <h3 className="error-title">Account Not Found</h3>
                    <p className="error-message">
                        We could not find your directory account. If you believe this is in error,
                        please contact IT support. If you are not a resident or employee,
                        this would be normal and you would not have one.
                    </p>
                </div>
            </div>
        </>
    }

    if(directoryType === "EMPLOYEE"){
        return <EmployeeSelfService employeeInfo={directoryInfo}/>
    }

    return <ResidentSelfService resident={directoryInfo}/>


}
export default SelfService;
