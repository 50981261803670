import {useState, useEffect} from "react";
import { DirectLine } from 'botframework-directlinejs';
import ReactWebChat from 'botframework-webchat';
import {doGet} from "../../services/rest";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";

function Copilot () {
    const [directLine, setDirectLine] = useState(null);
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if(user && !loading) {
            doGet("ai").then((data) => {
                // Initialize with your Direct Line secret
                const directLineClient = new DirectLine({
                    secret: data.key,
                });
                setDirectLine(directLineClient);
            })
        }
    }, [user, loading]);
    return (
        <>
            <div style={{ height: '500px', width: '400px' }}>
                {directLine && (
                    <ReactWebChat directLine={directLine} userID="user-id" />
                )}
            </div>
        </>
    )
}

export default Copilot;
