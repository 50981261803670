import { createContext, useState, useContext, useEffect } from 'react';
import {auth} from "../../firebase/firebase";
import {doGet} from "../../services/rest";
import {useAuthState} from "react-firebase-hooks/auth";


// Create the context
const UserPreferencesContext = createContext();

// Create a provider component
export const UserPreferencesProvider = ({ children }) => {
    const [user] = useAuthState(auth);
    const [isLegacyView, setIsLegacyView] = useState(false);
    const [preferencesLoading, setPreferencesLoading] = useState(true);

    useEffect(() => {
        if (user) {
            try {
                setPreferencesLoading(true);
                doGet("my-settings/legacy-setting")
                    .then(data => {
                        setIsLegacyView(data.isLegacyView);
                        setPreferencesLoading(false);
                    })
                    .catch(error => {
                        console.warn("Error fetching legacy setting:", error);
                        setIsLegacyView(false);
                        setPreferencesLoading(false);
                    });
            } catch (error) {
                console.error("Synchronous error in legacy setting fetch:", error);
                setIsLegacyView(false);
                setPreferencesLoading(false);
            }
        } else {
            setPreferencesLoading(false);
        }
    }, [user]);

    const updateLegacyViewSetting = async (newValue) => {
        if(newValue !== isLegacyView) {
            setIsLegacyView(newValue);
        }
    }

    return (
        <UserPreferencesContext.Provider value={{ isLegacyView, preferencesLoading, updateLegacyViewSetting }}>
            {children}
        </UserPreferencesContext.Provider>
    );
};

// Create a custom hook for easy consumption
export const useUserPreferences = () => useContext(UserPreferencesContext);
