import {useEffect, useState} from "react";
import Heading from "../Heading";
import {doGet, doPostBody, mainUrl} from "../../services/rest";
import {auth} from "../../firebase/firebase";
import SmallSpinner from "../SmallSpinner";

function EmployeeSelfService({employeeInfo}){
    const [myInfo, setMyInfo] = useState({});
    const [editingVehicle, setEditingVehicle] = useState(null);
    const [newVehicle, setNewVehicle] = useState({
        year: '',
        make: '',
        model: '',
        color: '',
        licencePlate: '',
        gscId: employeeInfo.employeeID
    });
    const [showVehicleForm, setShowVehicleForm] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [savingUser, setSavingUser] = useState(false);
    const [editingVehicleForm, setEditingVehicleForm] = useState(false);

    useEffect(() => {
        if (employeeInfo) {
            setMyInfo(employeeInfo);
        }
    }, [employeeInfo]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMyInfo({
            ...myInfo,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Here you would typically send the updated data to your backend
        // myInfo is already updated through handleInputChange
        setSavingUser(true);
        const resp = await doPostBody(`self-service/update-directory-employee`, myInfo);
        if(resp.id){
            setSuccessMessage("Your profile has been updated.");
            setSavingUser(false);
            setMyInfo(resp);
        }else{
            setSuccessMessage("Something went wrong.");
            setSavingUser(false);
        }

    };

    const handleVehicleInputChange = (e) => {
        const { name, value } = e.target;
        if (editingVehicle !== null) {
            const updatedVehicles = [...myInfo.employeeVehiclesList];
            updatedVehicles[editingVehicle] = {
                ...updatedVehicles[editingVehicle],
                [name]: value
            };
            setMyInfo({
                ...myInfo,
                employeeVehiclesList: updatedVehicles
            });
        } else {
            setNewVehicle({
                ...newVehicle,
                [name]: value
            });
        }
    };

    const startEditingVehicle = (index) => {
        setEditingVehicle(index);
        setShowVehicleForm(true);
    };

    const cancelVehicleEdit = () => {
        setEditingVehicle(null);
        setShowVehicleForm(false);
        setNewVehicle({
            year: '',
            make: '',
            model: '',
            color: '',
            licencePlate: '',
            gscId: employeeInfo.employeeID
        });
    };

    const saveVehicle = async () => {
        if (editingVehicle !== null) {
            setEditingVehicleForm(true);
            const updatedVehicles = [...myInfo.employeeVehiclesList];
            const resp = await doPostBody(`self-service/update-vehicle-employee`, updatedVehicles[editingVehicle]);
            if(resp.id){
                setSuccessMessage("Your vehicle has been updated.");
                setMyInfo(resp);
                setEditingVehicleForm(false);
            }else{
                setSuccessMessage("Something went wrong.");
                setEditingVehicleForm(false);
            }
            setEditingVehicle(null);
        } else {
            // Add new vehicle
            const resp = await doPostBody(`self-service/update-vehicle-employee`, newVehicle);
            if(resp.id){
                setSuccessMessage("Your vehicle has been updated.");
                setMyInfo(resp);
                setEditingVehicleForm(false);
            }else{
                setSuccessMessage("Something went wrong.");
                setEditingVehicleForm(false);
            }
            setNewVehicle({
                year: '',
                make: '',
                model: '',
                color: '',
                licencePlate: '',
                gscId: employeeInfo.employeeID
            });
        }
        setShowVehicleForm(false);
        setSuccessMessage('Vehicle information updated successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
    };

    const startAddingVehicle = () => {
        setEditingVehicle(null);
        setShowVehicleForm(true);
    };

    const deleteVehicle = async (index) => {
        if (window.confirm('Are you sure you want to remove this vehicle?')) {
            const updatedVehicles = [...myInfo.employeeVehiclesList];
            const resp = await doGet(`self-service/delete-employee-vehicle/${updatedVehicles[index].id}`);
            setMyInfo(resp);
        }
    };

    return <>
        <Heading title={'Self Service'} />
        <div className={'inner-container'}>
              <div className="employee-profile">
                <div className="profile-header">
                    <div className="profile-photo">
                            <img
                                src={mainUrl + "menu/image/" + auth.currentUser?.uid}
                                alt={`${myInfo.firstName} ${myInfo.lastName}`}
                            />
                    </div>
                    <div className="profile-title">
                        <h2>{myInfo.firstName} {myInfo.lastName}</h2>
                        <p className="employee-position">{myInfo.position}</p>
                        <p className="employee-department">{myInfo.teamDepartments?.name || myInfo.department}</p>
                    </div>
                </div>

                {/* Form is always shown */}
                <form className="profile-form" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={myInfo.firstName || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={myInfo.lastName || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="mobile">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={myInfo.phone || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile Number</label>
                            <input
                                type="tel"
                                id="mobile"
                                name="mobile"
                                value={myInfo.mobile || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={myInfo.email || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="position">Position</label>
                            <input
                                type="text"
                                id="position"
                                name="position"
                                value={myInfo.position || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="photoPermissions">Photo Permission</label>
                            <select
                                id="photoPermissions"
                                name="photoPermissions"
                                value={myInfo.photoPermissions || '0'}
                                onChange={handleInputChange}
                            >
                                <option value="">Select permission</option>
                                <option value="1" selected={myInfo.photoPermissions === "1"}>Yes</option>
                                <option value="0" selected={myInfo.photoPermissions === "0"}>No</option>
                            </select>
                        </div>
                        <div className="form-group">
                        </div>
                    </div>

                    <div className="form-actions">
                        {savingUser ? <SmallSpinner />
                        : <button type="submit" className="save-button">Save Changes</button>}
                    </div>
                    {successMessage && (
                        <div className="success-message">
                            {successMessage}
                        </div>
                    )}
                </form>

                <div className="profile-details">
                    <div className="detail-group">
                        <h3>Additional Information</h3>
                        <div className="detail-row">
                            <span className="detail-label">Employee ID:</span>
                            <span className="detail-value">{myInfo.employeeID}</span>
                        </div>
                        {myInfo.extension && (
                            <div className="detail-row">
                                <span className="detail-label">Extension:</span>
                                <span className="detail-value">{myInfo.extension}</span>
                            </div>
                        )}
                        <div className="detail-row">
                            <span className="detail-label">Community:</span>
                            <span className="detail-value">{myInfo?.teamDepartments?.community?.name ?? ''}</span>
                        </div>
                        <div className="detail-row">
                            <span className="detail-label">Department:</span>
                            <span className="detail-value">{myInfo?.teamDepartments?.name ?? ''}</span>
                        </div>
                    </div>
                </div>

                <div className="vehicles-section">
                    <div className="section-header">
                        <h3>Registered Vehicles</h3>
                        <button
                            className="add-vehicle-button"
                            onClick={startAddingVehicle}
                            disabled={showVehicleForm}
                        >
                            Add Vehicle
                        </button>
                    </div>

                    {showVehicleForm && (
                        <div className="vehicle-form">
                            <h4>{editingVehicle !== null ? 'Edit Vehicle' : 'Add New Vehicle'}</h4>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="year">Year</label>
                                    <input
                                        type="number"
                                        id="year"
                                        name="year"
                                        value={editingVehicle !== null
                                            ? myInfo.employeeVehiclesList[editingVehicle].year
                                            : newVehicle.year}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="make">Make</label>
                                    <input
                                        type="text"
                                        id="make"
                                        name="make"
                                        value={editingVehicle !== null
                                            ? myInfo.employeeVehiclesList[editingVehicle].make
                                            : newVehicle.make}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="model">Model</label>
                                    <input
                                        type="text"
                                        id="model"
                                        name="model"
                                        value={editingVehicle !== null
                                            ? myInfo.employeeVehiclesList[editingVehicle].model
                                            : newVehicle.model}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="color">Color</label>
                                    <input
                                        type="text"
                                        id="color"
                                        name="color"
                                        value={editingVehicle !== null
                                            ? myInfo.employeeVehiclesList[editingVehicle].color
                                            : newVehicle.color}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="licencePlate">License Plate</label>
                                    <input
                                        type="text"
                                        id="licencePlate"
                                        name="licencePlate"
                                        value={editingVehicle !== null
                                            ? myInfo.employeeVehiclesList[editingVehicle].licencePlate
                                            : newVehicle.licencePlate}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            {editingVehicleForm ? <SmallSpinner /> :
                            <div className="form-actions">
                                <button type="button" className="save-button" onClick={saveVehicle}>
                                    Save Vehicle
                                </button>
                                <button type="button" className="cancel-button" onClick={cancelVehicleEdit}>
                                    Cancel
                                </button>
                            </div> }
                        </div>
                    )}

                    <div className="vehicles-list">
                        {myInfo.employeeVehiclesList && myInfo.employeeVehiclesList.length > 0 ? (
                            myInfo.employeeVehiclesList.map((vehicle, index) => (
                                <div key={index} className="vehicle-card">
                                    <div className="vehicle-info">
                                        <h4>{vehicle.year} {vehicle.make} {vehicle.model}</h4>
                                        <div className="vehicle-details">
                                            <span className="vehicle-color">{vehicle.color}</span>
                                            <span className="vehicle-plate">{vehicle.licencePlate}</span>
                                        </div>
                                    </div>
                                    <div className="vehicle-actions">
                                        <button
                                            className="edit-vehicle-button"
                                            onClick={() => startEditingVehicle(index)}
                                            disabled={showVehicleForm}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="delete-vehicle-button"
                                            onClick={() => deleteVehicle(index)}
                                            disabled={showVehicleForm}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no-vehicles">
                                <p>No vehicles registered. Click "Add Vehicle" to register a vehicle.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default EmployeeSelfService;
