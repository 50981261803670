import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {doGet, doPost, doPostBody, imageUploadHandler} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import { Editor } from '@tinymce/tinymce-react';

function EditHtml() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [html, setHtml] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    // Styles
    const styles = {
        container: {
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '16px'
        },
        card: {
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            padding: '24px'
        },
        heading: {
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '24px'
        },
        formGroup: {
            marginBottom: '16px'
        },
        label: {
            display: 'block',
            color: '#4a5568',
            fontSize: '14px',
            fontWeight: 'bold',
            marginBottom: '8px'
        },
        input: {
            width: '100%',
            padding: '8px 12px',
            border: '1px solid #e2e8f0',
            borderRadius: '4px',
            fontSize: '16px',
            lineHeight: '1.5'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        button: {
            fontWeight: 'bold',
            padding: '8px 16px',
            borderRadius: '4px',
            cursor: 'pointer',
            border: 'none'
        },
        cancelButton: {
            backgroundColor: '#718096',
            color: 'white',
            marginRight: '8px'
        },
        saveButton: {
            backgroundColor: '#3182ce',
            color: 'white'
        },
        disabledButton: {
            opacity: '0.5',
            cursor: 'not-allowed'
        }
    };

    useEffect(() => {
        loadPages();
    }, [id]);

    const loadPages = async () => {
        try {
            const htmlData = await doGet(`html/load/${id}`);
            setHtml(htmlData);
            setLoading(false);
        } catch (error) {
            console.error("Error loading HTML page:", error);
            setLoading(false);
        }
    };

    const handleTitleChange = (e) => {
        setHtml(prev => ({
            ...prev,
            title: e.target.value
        }));
    };

    const handleHtmlChange = (content) => {
        setHtml(prev => ({
            ...prev,
            html: content
        }));
    };


    const handleSave = async () => {
        try {
            if(!html.title){
                window.alert("Please enter a title");
                return;
            }
            if(!html.html){
                window.alert("Please enter a html");
                return;
            }
            setSaving(true);
            // Replace with your actual save endpoint
            await doPostBody(`html/save/${id}`, html);
            setSaving(false);
            // Navigate back or show success message
            navigate(-1); // Adjust as needed
        } catch (error) {
            console.error("Error saving HTML page:", error);
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <>
                <Heading />
                <MySpinner />
            </>
        );
    }

    return (
        <div>
            <Heading />
            <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.heading}>Edit HTML Page</h1>

                <div style={styles.formGroup}>
                    <label style={styles.label} htmlFor="title">
                        Page Title
                    </label>
                    <input
                        id="title"
                        type="text"
                        style={styles.input}
                        value={html?.title || ''}
                        onChange={handleTitleChange}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label}>
                        Page Content
                    </label>
                    <Editor
                        onEditorChange={(e) => handleHtmlChange(e)}
                        licenseKey={'gpl'}
                        value={html?.html || ''}
                        init={{
                            height: 500,
                            menubar: false,
                            advcode_inline: true,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                            ],
                            toolbar: 'undo redo | blocks | link table|' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | image | bullist numlist outdent indent | ' +
                                'removeformat | toggle | code',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            automatic_uploads: true,
                            images_upload_handler: imageUploadHandler,
                        }}
                        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    />
                </div>

                <div style={styles.buttonContainer}>
                    <button
                        style={{...styles.button, ...styles.cancelButton}}
                        onClick={() => navigate(-1)} // Adjust as needed
                    >
                        Cancel
                    </button>
                    <button
                        style={{
                            ...styles.button,
                            ...styles.saveButton,
                            ...(saving ? styles.disabledButton : {})
                        }}
                        onClick={handleSave}
                        disabled={saving}
                    >
                        {saving ? 'Saving...' : 'Save Changes'}
                    </button>
                </div>
            </div>
            </div>
        </div>
    );
}

export default EditHtml;
