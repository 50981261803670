import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "./../css/main-app.css";
import Logo from './../images/GSC.png';
import MySpinner from "./MySpinner";
import {doPost} from "../services/rest";
import {signInWithCustomToken} from "firebase/auth";
import {auth} from "../firebase/firebase";

function PasswordResetPage() {
    const {token} = useParams();
    const navigate = useNavigate();
    const [resetData, setResetData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [username, setUsername] = useState();
    const navigation = useNavigate();

    useEffect(() => {
        const data = atob(token);
        const split = data.split(":")
        if(split.length === 2) {
            setUsername(split[0])
            setIsLoading(false)
        }
    }, [token]);

    const onChange = event => {
        setResetData(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const validatePassword = (password) => {
        // Add your password validation rules here
        const minLength = 8;
        if (password.length < minLength) {
            return `Password must be at least ${minLength} characters long`;
        }
        return null;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Reset messages
        setErrorMessage(null);
        setSuccessMessage(null);

        // Check if passwords match
        if (resetData.password !== resetData.confirmPassword) {
            setErrorMessage("Passwords do not match");
            return;
        }

        // Validate password
        const validationError = validatePassword(resetData.password);
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("password", resetData.password);
            formData.append("token", token);
            const resp = await doPost('login/reset', formData);
            if(resp.success){
                setSuccessMessage(resp.message);
                if(resp.loginKey){
                    setTimeout(async () => {
                        try {
                            await signInWithCustomToken(auth, resp.loginKey);
                            console.log('User signed in successfully with token');
                            navigation('/');
                            // Any additional logic after successful login
                        } catch (error) {
                            console.error('Firebase authentication error:', error);
                            navigation('/login');
                        }
                    }, 3000);
                }else{
                    setTimeout(() => {
                        navigation('/login');
                    }, 3000);
                }
            }else{
                setErrorMessage(resp.message);
            }
        } catch (error) {
            setErrorMessage("An error occurred. Please try again later.");
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="login-page-header"><h3>Garden Spot Communities</h3></div>

            {isLoading ? <MySpinner /> : (
                <div className="login-container">
                    <div className="login-header">
                        <img src={Logo} className="login-logo" alt="GSC Logo" />
                        <h2>Reset Password</h2>
                    </div>

                    {!token || !username ? (
                        <div className="login-error">
                            Invalid or expired password reset link. Please request a new password reset.
                        </div>
                    ) : (
                        <>
                            <div className="login-input-group">
                                <label htmlFor="password">New Password</label>
                                <input
                                    id="password"
                                    className="login-field"
                                    onChange={onChange}
                                    type="password"
                                    placeholder="Enter your new password"
                                    name="password"
                                    required
                                />
                            </div>

                            <div className="login-input-group">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    id="confirmPassword"
                                    className="login-field"
                                    onChange={onChange}
                                    type="password"
                                    placeholder="Confirm your new password"
                                    name="confirmPassword"
                                    required
                                />
                            </div>

                            {errorMessage && <div className="login-error">{errorMessage}</div>}
                            {successMessage && <div className="login-success">{successMessage}</div>}

                            <button
                                onClick={handleSubmit}
                                type="submit"
                                className="login-button"
                            >
                                Reset Password
                            </button>
                        </>
                    )}

                    <div className="login-footer-link" onClick={() => navigate("/login")}>
                        Back to Login
                    </div>
                </div>
            )}

            <div className="login-page-footer">&copy; {new Date().getFullYear()} Garden Spot Communities</div>
        </>
    );
}

export default PasswordResetPage;
