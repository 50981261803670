import {useEffect, useState} from "react";
import Heading from "../Heading";
import {doGet, doPostBody, mainUrl} from "../../services/rest";
import {auth} from "../../firebase/firebase";
import SmallSpinner from "../SmallSpinner";

function ResidentSelfService({resident}) {
    const [myInfo, setMyInfo] = useState({});
    const [editingVehicle, setEditingVehicle] = useState(null);
    const [newVehicle, setNewVehicle] = useState({
        year: '',
        make: '',
        model: '',
        color: '',
        licenceplate: '',
        tag: '',
        residentId: resident.residentNumber
    });
    const [showVehicleForm, setShowVehicleForm] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [savingUser, setSavingUser] = useState(false);
    const [savingVehicle, setSavingVehicle] = useState(false);
    useEffect(() => {
        if (resident) {
            setMyInfo(resident);
        }
    }, [resident]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMyInfo({
            ...myInfo,
            [name]: value
        });
    };

    const handleLocationInputChange = (e) => {
        const { name, value } = e.target;
        setMyInfo({
            ...myInfo,
            locationWithNeighborhood: {
                ...myInfo.locationWithNeighborhood,
                [name]: value
            }
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setMyInfo({
            ...myInfo,
            [name]: checked
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSavingUser(true);
        const resp = await doPostBody("self-service/update-directory-resident", myInfo)
        if(resp.id){
            setMyInfo(resp)
            setSuccessMessage('Profile updated successfully!');
        }else{
            setSuccessMessage("something went wrong!");
        }
        setSavingUser(false);
    };

    const handleVehicleInputChange = (e) => {
        const { name, value } = e.target;
        if (editingVehicle !== null) {
            const updatedVehicles = [...myInfo.residentVehicles.vehicles];
            updatedVehicles[editingVehicle] = {
                ...updatedVehicles[editingVehicle],
                [name]: value
            };
            setMyInfo({
                ...myInfo,
                residentVehicles: {
                    ...myInfo.residentVehicles,
                    vehicles: updatedVehicles
                }
            });
        } else {
            setNewVehicle({
                ...newVehicle,
                [name]: value
            });
        }
    };

    const startEditingVehicle = (index) => {
        setEditingVehicle(index);
        setShowVehicleForm(true);
    };

    const cancelVehicleEdit = () => {
        setEditingVehicle(null);
        setShowVehicleForm(false);
        setNewVehicle({
            year: '',
            make: '',
            model: '',
            color: '',
            licenceplate: '',
            tag: '',
            residentId: resident.residentNumber
        });
    };

    const saveVehicle = async () => {
        if (editingVehicle !== null) {
            const updatedVehicles = [...myInfo.residentVehicles.vehicles];
            setSavingVehicle(true);
            const resp = await doPostBody("self-service/update-vehicle-resident", updatedVehicles[editingVehicle]);
            if(resp.id){
                setMyInfo(resp)
                setSuccessMessage('Vehicle added successfully!');
            }else{
                setSuccessMessage('Something went wrong!');
            }
            setSavingVehicle(false);
            setEditingVehicle(null);
        } else {
            // Add new vehicle
            setSavingVehicle(true);
            const resp = await doPostBody("self-service/update-vehicle-resident", newVehicle)
            if(resp.id){
                setMyInfo(resp)
                setSuccessMessage('Vehicle added successfully!');
            }else{
                setSuccessMessage('Something went wrong!');
            }
            setSavingVehicle(false);
            setNewVehicle({
                year: '',
                make: '',
                model: '',
                color: '',
                licenceplate: '',
                tag: '',
                residentId: resident.residentNumber
            });
        }
        setShowVehicleForm(false);
    };

    const startAddingVehicle = () => {
        setEditingVehicle(null);
        setShowVehicleForm(true);
    };

    const deleteVehicle = async (index) => {
        if (window.confirm('Are you sure you want to remove this vehicle?')) {
            const updatedVehicles = [...myInfo.residentVehicles.vehicles];
            const resp = await doGet(`self-service/delete-resident/vehicle/${resident.residentNumber}/${updatedVehicles[index].id}`);
            if(resp.id){
                setMyInfo(resp)
                setSuccessMessage('Vehicle removed successfully!');
            }else {
                setSuccessMessage('Something went wrong!');
            }
        }
    };

    // Function to format the date for display
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    };

    return <>
        <Heading title={'Resident Self Service'} />
        <div className={'inner-container'}>


            <div className="employee-profile">
                <div className="profile-header">
                    <div className="profile-photo">
                            <img
                                src={mainUrl + "menu/image/" + auth.currentUser?.uid}
                                alt={`${myInfo.firstName} ${myInfo.lastName}`}
                            />
                    </div>
                    <div className="profile-title">
                        <h2>
                            {myInfo.preferredName || myInfo.firstName} {myInfo.initial ? myInfo.initial + '. ' : ''}{myInfo.lastName}
                        </h2>
                        <p className="employee-position">Resident #{myInfo.residentNumber}</p>
                        <p className="employee-department">
                            {myInfo.locationWithNeighborhood?.homeNumber} {myInfo.locationWithNeighborhood?.address}
                        </p>
                    </div>
                </div>

                {/* Form is always shown */}
                <form className="profile-form" onSubmit={handleSubmit}>
                    <h3 className="form-section-title">Personal Information</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={myInfo.firstName || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={myInfo.lastName || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="initial">Middle Initial</label>
                            <input
                                type="text"
                                id="initial"
                                name="initial"
                                value={myInfo.initial || ''}
                                onChange={handleInputChange}
                                maxLength="1"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="preferredName">Preferred Name</label>
                            <input
                                type="text"
                                id="preferredName"
                                name="preferredName"
                                value={myInfo.preferredName || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="cellNumber">Cell Phone</label>
                            <input
                                type="tel"
                                id="cellNumber"
                                name="cellNumber"
                                value={myInfo.cellNumber || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="emailAddress">Email</label>
                            <input
                                type="email"
                                id="emailAddress"
                                name="emailAddress"
                                value={myInfo.emailAddress || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <h3 className="form-section-title">Home Information</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="homePhone">Home Phone</label>
                            <input
                                type="tel"
                                id="homePhone"
                                name="homePhone"
                                value={myInfo.locationWithNeighborhood?.homePhone || ''}
                                onChange={handleLocationInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="homeEmail">Home Email</label>
                            <input
                                type="email"
                                id="homeEmail"
                                name="homeEmail"
                                value={myInfo.locationWithNeighborhood?.homeEmail || ''}
                                onChange={handleLocationInputChange}
                            />
                        </div>
                    </div>

                    <h3 className="form-section-title">Preferences</h3>
                    <div className="form-row">
                        <div className="form-group checkbox-group">
                            <label className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="displayBirthdate"
                                    name="displayBirthdate"
                                    checked={myInfo.displayBirthdate || false}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkbox-text">Display Birthdate in Directory</span>
                            </label>
                        </div>
                        <div className="form-group checkbox-group">
                            <label className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="residentPhotoPermission"
                                    name="residentPhotoPermission"
                                    checked={myInfo.residentPhotoPermission || false}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkbox-text">Photo Permission</span>
                            </label>
                        </div>
                    </div>

                    <div className="form-actions">
                        {savingUser ? <SmallSpinner /> : <button type="submit" className="save-button">Save Changes</button>}
                    </div>
                    {successMessage && (
                        <div className="success-message">
                            {successMessage}
                        </div>
                    )}
                </form>

                {/* Display additional read-only information */}
                <div className="profile-details">
                    <div className="detail-group">
                        <h3>Additional Information</h3>
                        <div className="detail-row">
                            <span className="detail-label">Resident ID:</span>
                            <span className="detail-value">{myInfo.residentNumber}</span>
                        </div>
                        {myInfo.residentBirthdate && (
                            <div className="detail-row">
                                <span className="detail-label">Birthdate:</span>
                                <span className="detail-value">{formatDate(myInfo.residentBirthdate)}</span>
                            </div>
                        )}
                        <div className="detail-row">
                            <span className="detail-label">Neighborhood:</span>
                            <span className="detail-value">
                                {myInfo.locationWithNeighborhood?.residentNeighborhood?.name || 'Not assigned'}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="vehicles-section">
                    <div className="section-header">
                        <h3>Registered Vehicles</h3>
                        <button
                            className="add-vehicle-button"
                            onClick={startAddingVehicle}
                            disabled={showVehicleForm}
                        >
                            Add Vehicle
                        </button>
                    </div>

                    {showVehicleForm && (
                        <div className="vehicle-form">
                            <h4>{editingVehicle !== null ? 'Edit Vehicle' : 'Add New Vehicle'}</h4>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="year">Year</label>
                                    <input
                                        type="text"
                                        id="year"
                                        name="year"
                                        value={editingVehicle !== null
                                            ? myInfo.residentVehicles.vehicles[editingVehicle].year
                                            : newVehicle.year}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="make">Make</label>
                                    <input
                                        type="text"
                                        id="make"
                                        name="make"
                                        value={editingVehicle !== null
                                            ? myInfo.residentVehicles.vehicles[editingVehicle].make
                                            : newVehicle.make}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="model">Model</label>
                                    <input
                                        type="text"
                                        id="model"
                                        name="model"
                                        value={editingVehicle !== null
                                            ? myInfo.residentVehicles.vehicles[editingVehicle].model
                                            : newVehicle.model}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="color">Color</label>
                                    <input
                                        type="text"
                                        id="color"
                                        name="color"
                                        value={editingVehicle !== null
                                            ? myInfo.residentVehicles.vehicles[editingVehicle].color
                                            : newVehicle.color}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="licenceplate">License Plate</label>
                                    <input
                                        type="text"
                                        id="licenceplate"
                                        name="licenceplate"
                                        value={editingVehicle !== null
                                            ? myInfo.residentVehicles.vehicles[editingVehicle].licenceplate
                                            : newVehicle.licenceplate}
                                        onChange={handleVehicleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tag">Tag/Permit Number</label>
                                    <input
                                        type="text"
                                        id="tag"
                                        name="tag"
                                        value={editingVehicle !== null
                                            ? myInfo.residentVehicles.vehicles[editingVehicle].tag
                                            : newVehicle.tag}
                                        onChange={handleVehicleInputChange}
                                    />
                                </div>
                            </div>
                            {savingVehicle ? <SmallSpinner /> : <div className="form-actions">
                                <button type="button" className="save-button" onClick={saveVehicle}>
                                    Save Vehicle
                                </button>
                                <button type="button" className="cancel-button" onClick={cancelVehicleEdit}>
                                    Cancel
                                </button>
                            </div>}
                        </div>
                    )}

                    <div className="vehicles-list">
                        {myInfo.residentVehicles?.vehicles && myInfo.residentVehicles.vehicles.length > 0 ? (
                            myInfo.residentVehicles.vehicles.map((vehicle, index) => (
                                <div key={index} className="vehicle-card">
                                    <div className="vehicle-info">
                                        <h4>{vehicle.year} {vehicle.make} {vehicle.model}</h4>
                                        <div className="vehicle-details">
                                            <span className="vehicle-color">{vehicle.color}</span>
                                            <span className="vehicle-plate">{vehicle.licenceplate}</span>
                                            {vehicle.tag && (
                                                <span className="vehicle-tag">Tag: {vehicle.tag}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="vehicle-actions">
                                        <button
                                            className="edit-vehicle-button"
                                            onClick={() => startEditingVehicle(index)}
                                            disabled={showVehicleForm}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="delete-vehicle-button"
                                            onClick={() => deleteVehicle(index)}
                                            disabled={showVehicleForm}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no-vehicles">
                                <p>No vehicles registered. Click "Add Vehicle" to register a vehicle.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ResidentSelfService;
