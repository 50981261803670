import FemalePlaceholder from "../../images/photo-placeholder-female-thumb.jpg";
import MalePlaceholder from "../../images/photo-placeholder-male-thumb.jpg";
import { defaultColor } from "../../defaults/AppConfig";

function ResidentImageSingle({resident, thumb}) {
    let imgSrc = '';
    if (resident.photo) {
        let newPhoto = resident.photo;

        if (thumb) {
            let photoFiles = resident.photo.split('.');
            newPhoto = `${photoFiles[0]}-thumb.${photoFiles[1]}`;
        }
        imgSrc = `https://photos.gsv.gsrdp.org/${newPhoto}`;

    } else {
        imgSrc = resident.residentGender === 'female'
            ? FemalePlaceholder
            : MalePlaceholder;
    }

    const containerStyle = thumb ? styles.containerThumb : styles.containerLarge;

    return (
        <div style={containerStyle}>
            <img
                style={thumb ? styles.image : styles.largeImage}
                src={imgSrc}
                alt="Resident"
            />
        </div>
    );
}

const styles = {
    containerThumb: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
    },
    containerLarge: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '15px',
    },
    image: {
        width: 140,
        height: 120,
        objectFit: 'cover',
        borderRadius: '12px',
        border: `3px solid ${defaultColor}`,
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
    },
    largeImage: {
        maxWidth: '100%',
        width: 280,
        height: 240,
        objectFit: 'cover',
        borderRadius: '16px',
        border: `4px solid ${defaultColor}`,
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)',
    },
};

export default ResidentImageSingle;
