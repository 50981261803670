import { useParams } from "react-router-dom";

function YouTubePlayer() {
    const { id } = useParams();

    const containerStyle = {
        margin: 0,
        padding: 0,
        height: "100vh",
        border: "none",
        display: "block",
        width: "100%",
        overflow: "hidden",
        paddingBottom: 100
    };

    const iframeStyle = {
        margin: 0,
        padding: 0,
        height: "100%",
        border: "none",
        display: "block",
        width: "100%",
        overflow: "hidden"
    };

    return (
        <div style={containerStyle}>
            <iframe
                id="thisIframe"
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${id}?rel=0&enablejsapi=1&playsinline=1&showInfo=0&controls=1&fullscreen=1`}
                frameBorder="0"
                allowFullScreen={true}
                style={iframeStyle}
            />
        </div>
    );
}

export default YouTubePlayer;
