import Heading from "./app/componants/Heading";
import {useEffect, useRef, useState} from "react";
import {loadMenu} from "./app/services/menu";
import {serviceUrl} from "./app/defaults/AppConfig";
import {useNavigate} from "react-router-dom";
import Stream from "./app/images/message-stream.png";
import Settings from "./app/images/settings.png";
import LogOut from "./app/images/log out.png";
import {auth, logOut} from "./app/firebase/firebase";
import {useSubscription} from "react-stomp-hooks";
import {useUserPreferences} from "./app/componants/context/UserPreferencesProvider";

function LegacyHome() {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const subMenuLevel = useRef(0);
    const subMenuTree = useRef({});
    const menuHistory = useRef([]);
    const navigate = useNavigate();
    const [subMenuItems, setSubMenuItems] = useState([]);
    const [menuAnimation, setMenuAnimation] = useState('');
    const {updateLegacyViewSetting} = useUserPreferences();
    useSubscription(`/user/${auth.currentUser.email}/settings`, (message) => {
        try{
            const user = JSON.parse(message.body)
            updateLegacyViewSetting(user.legacyView);
        }catch(err){
            console.log(err);
        }
    })

    useEffect(() => {
        loadMenu().then(res => {
            setMenuItems(res)
        })
    }, []);

    const myNavigation = (item) => {
        if (item.menuItemTypes.jsRoute === '/web-links') {
            if (item.singleLink) {
                window.open(item.singleUrl, '_blank')
            } else {
                navigate(`/web-links/${item.id}/${item.title}`)
            }
        } else if (item.menuItemTypes.jsRoute === '/documents') {
            navigate(`/documents/${item.id}`)
        } else if (item.menuItemTypes.jsRoute === '/media') {
            navigate(`/media/${item.id}`)
        } else if (item.menuItemTypes.jsRoute === '/submenu') {
            if (item.children) {
                // Store current menu for history
                if (subMenuLevel.current === 0) {
                    menuHistory.current = [];
                    menuHistory.current[0] = menuItems;
                } else {
                    menuHistory.current[subMenuLevel.current] = subMenuItems;
                }

                // Store children for this level
                subMenuTree.current[subMenuLevel.current] = item.children;
                subMenuLevel.current += 1;

                // First set submenu items
                setSubMenuItems(item.children);

                // Show submenu with the slide-in animation
                setShowSubMenu(true);
                setMenuAnimation('slide-in');

                // Clear animation class after transition completes
                setTimeout(() => {
                    setMenuAnimation('');
                }, 300);
            }
        } else if (item.menuItemTypes.jsRoute === '/view') {
            navigate(`/view/${item.id}`);
        }else if (item.menuItemTypes.jsRoute === '/magazine'){
            navigate(`/magazine/${item.issuuConnection.id}`);
        } else {
            navigate(item.menuItemTypes.jsRoute)
        }
    }

    const goBack = () => {
        // Calculate the previous level
        const newLevel = subMenuLevel.current - 1;

        // Add animation class for sliding out
        setMenuAnimation('slide-out');

        setTimeout(() => {
            subMenuLevel.current = newLevel;

            if (newLevel === 0) {
                // If going back to main menu
                setShowSubMenu(false);
                setMenuAnimation('');
            } else {
                // If going back to previous submenu
                setSubMenuItems(menuHistory.current[newLevel]);
                setMenuAnimation('slide-back-in');
                setTimeout(() => setMenuAnimation(''), 300);
            }
        }, 300);
    }

    const DefaultIcon = ({ title }) => {
        return (
            <div style={styles.defaultIcon}>
                {title}
            </div>
        );
    };

    const logOutButton = () => {
        if(window.confirm("Are you sure you want to log out?")){
            logOut();
        }
    }

    const IconItem = ({ item, onClick }) => {
        const [isHovered, setIsHovered] = useState(false);

        const itemStyle = {
            ...styles.iconItem,
            ...(isHovered ? styles.iconItemHover : {})
        };

        const hasSubmenu = item.menuItemTypes?.jsRoute === '/submenu';

        return (
            <div
                style={itemStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => onClick(item)}
            >
                <div style={styles.iconContainer}>
                    {item.legacyIcon ? (
                        <LegacyIcon iconUrl={item.legacyIcon} title={item.title} />
                    ) : (
                        <DefaultIcon title={item.title} />
                    )}
                </div>
                {hasSubmenu && (
                    <div style={styles.submenuIndicator}>›</div>
                )}
            </div>
        );
    };

    const LegacyIcon = ({ iconUrl, title }) => {
        const [imageError, setImageError] = useState(false);

        const handleImageError = () => {
            setImageError(true);
        };

        return (
            <>
                {!imageError ? (
                    <img
                        src={serviceUrl+'menu/icon/'+iconUrl}
                        alt={title}
                        style={styles.iconImage}
                        onError={handleImageError}
                    />
                ) : (
                    <DefaultIcon title={title} />
                )}
            </>
        );
    };

    const BackButton = () => (
        <div
            style={styles.backButton}
            onClick={goBack}
        >
            <span style={styles.backArrow}>←</span> Back
        </div>
    );

    const LegacyIconGrid = ({ items, isSubmenu = false }) => {
        if (!items || items.length === 0) {
            return <div style={styles.noItems}>No menu items available</div>;
        }

        return (
            <div>
                {isSubmenu && <BackButton />}
                <h2 style={styles.heading}>{isSubmenu ? "Submenu" : "Menu Items"}</h2>
                <div style={styles.gridContainer}>
                    {!isSubmenu && (
                        <div
                            style={styles.iconItem}
                            onClick={() => navigate("/stream")}
                        >
                            <div style={styles.iconContainer}>
                                <img
                                    src={Stream}
                                    alt={"stream message"}
                                    style={styles.iconImage}
                                />
                            </div>
                        </div>
                    )}

                    {items.map((item) => (
                        <IconItem
                            key={item.id}
                            item={item}
                            onClick={() => myNavigation(item)}
                        />
                    ))}

                    {!isSubmenu && (
                        <>
                            <div
                                style={styles.iconItem}
                                onClick={() => navigate("/my-settings")}
                            >
                                <div style={styles.iconContainer}>
                                    <img
                                        src={Settings}
                                        alt={"settings"}
                                        style={styles.iconImage}
                                    />
                                </div>
                            </div>
                            <div
                                style={styles.iconItem}
                                onClick={logOutButton}
                            >
                                <div style={styles.iconContainer}>
                                    <img
                                        src={LogOut}
                                        alt={"Log Out"}
                                        style={styles.iconImage}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <Heading title={showSubMenu ? "Submenu" : "Legacy Home"} />
            <div className="inner-container">
                <div className={`submenu-container ${menuAnimation}`}>
                    {showSubMenu ? (
                        <LegacyIconGrid
                            items={subMenuItems}
                            isSubmenu={true}
                        />
                    ) : (
                        <LegacyIconGrid
                            items={menuItems}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

const styles = {
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
        gap: '15px',
        width: '100%',
    },
    iconItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '8px',
        padding: '10px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        position: 'relative',
    },
    iconItemHover: {
        backgroundColor: '#f0f0f0',
    },
    iconContainer: {
        width: '130px',
        height: '130px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconImage: {
        width: '130px',
        height: 'auto',
        maxHeight: '130px',
        objectFit: 'contain',
    },
    defaultIcon: {
        width: '130px',
        height: '130px',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#555',
        fontSize: '16px',
        textAlign: 'center',
        padding: '10px',
        wordBreak: 'break-word',
    },
    heading: {
        marginBottom: '20px',
        fontSize: '1.5rem',
    },
    noItems: {
        color: '#888',
        fontSize: '16px',
    },
    submenuIndicator: {
        position: 'absolute',
        right: '10px',
        top: 'calc(50% - 10px)',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#555',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        cursor: 'pointer',
        width: 'fit-content',
    },
    backArrow: {
        marginRight: '5px',
        fontSize: '18px',
    },
    submenuContainer: {
        opacity: 1,
        transition: 'opacity 0.3s ease, transform 0.3s ease',
    },
    'slide-in': {
        animation: 'slideIn 0.3s forwards',
    },
    'slide-out': {
        animation: 'slideOut 0.3s forwards',
    },
    'slide-back-in': {
        animation: 'slideBackIn 0.3s forwards',
    },
};

export default LegacyHome;
