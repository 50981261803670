import {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";

function AppAdmin() {
    const [loading, setLoading] = useState(true)
    const [appLocations, setAppLocations] = useState([]);
    const [menuItems, setMenuItems] = useState([]);

    const iaAdmin = hasAdmin('ROLE_ADMIN');
    const navigate = useNavigate();

    useEffect(() => {
        if(!iaAdmin) {
            navigate("/")
        }
    }, [iaAdmin, navigate])

    useEffect(() => {
        doGet('app-admin').then(resp =>{
            setAppLocations(resp.appLocations)
            setMenuItems(resp.menuItems)
            setLoading(false)
        })
    }, []);

    if(loading){
        return(<>
            <Heading title="Admin" />
            <MySpinner />
        </>)
    }

    return(<>
        <Heading title="Admin" />
        <div className="inner-container" style={{paddingTop: 30}}>
            {/* Groups Section */}
            <div className="section-header">
                <div className="empty-space"></div>
                <h3>Groups</h3>
                <div className="add-new-button" onClick={() => navigate('/app-admin/app-locations')}>Add New</div>
            </div>

            <table className="admin-table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Display Name</th>
                    <th>Email Address</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {appLocations.map(appLocation => (
                    <tr key={appLocation.id}>
                        <td>{appLocation.locationName}</td>
                        <td>{appLocation.locationDisplayName}</td>
                        <td>{appLocation.emailAddress}</td>
                        <td>
                                <span
                                    className="edit-link"
                                    onClick={() => navigate(`/app-admin/app-locations/${appLocation.id}`)}
                                >
                                    Edit
                                </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className="section-divider"></div>

            {/* Menu Items Section */}
            <div className="section-header">
                <div className="empty-space"></div>
                <h3>Menu Items</h3>
                <div className="add-new-button" onClick={() => navigate('/app-admin/menu-items')}>Add New</div>
            </div>

            <table className="admin-table">
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {menuItems.map(menuItem => (
                    <tr key={menuItem.id}>
                        <td>{menuItem.title}</td>
                        <td>{menuItem.description}</td>
                        <td>
                                <span
                                    className="edit-link"
                                    onClick={() => navigate(`/app-admin/menu-items/${menuItem.id}`)}
                                >
                                    Edit
                                </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    </>)
}

export default AppAdmin;
