import React from 'react';
import ResidentImages from "./ResidentImages";
import TeamMemberImages from "./TeamMemberImages";
import {formatFirstNameLastName} from "./NameFormatters";

function DirectoryResultsDisplay({directoryResponse, displayType, onClick}) {
    const data = [
        {title: 'Residents', data: directoryResponse.residents ?? []},
        {title: 'Team Members', data: directoryResponse.teamMembers ?? []},
        {title: 'Campus Numbers', data: directoryResponse.campusNumbers ?? []},
    ];

    if (displayType === "1") {
        data.splice(1, 2);
    } else if (displayType === "2") {
        data.splice(0, 1);
        data.pop();
    } else if (displayType === "3") {
        data.splice(0, 2);
    }

    const handleClick = (data, title) => {
        onClick(data, title);
    }

    const renderCampusNumbers = (item, index) => (
        <div className="directory-card campus-card" key={index} onClick={() => handleClick(item, 'Campus Numbers')}>
            <a className="campus-link" href={`tel:${item.number}`}>
                <div className="campus-name">{item.label}</div>
                <div className="campus-number">{item.number}</div>
            </a>
        </div>
    );

    const renderResident = (item, index) => (
        <div className="directory-card resident-card" key={index} onClick={() => handleClick(item, 'Residents')}>
            <div className="card-image">
                <ResidentImages resident={item} thumb={true} />
            </div>
            <div className="card-content">
                <h3 className="card-name">{formatFirstNameLastName(item.residentsNew)}</h3>
                <div className="card-detail">{item.homeNumber} {item.address}</div>
                {item?.neighborhood?.neighborHoodCommunityName && (
                    <div className="card-neighborhood">{item.neighborhood.neighborHoodCommunityName}</div>
                )}
            </div>
        </div>
    );

    const renderTeamMember = (item, index) => (
        <div className="directory-card team-card" key={index} onClick={() => handleClick(item, 'Team Members')}>
            <div className="card-image">
                <TeamMemberImages teamMember={item} thumb={true} />
            </div>
            <div className="card-content">
                <h3 className="card-name">{item.fname} {item.lname}</h3>
                <div className="card-detail">{item.community}</div>
                {item?.teamDepartments?.name && (
                    <div className="card-department">{item.teamDepartments.name}</div>
                )}
                <div className="card-position">{item.position}</div>
            </div>
        </div>
    );

    const renderItems = (section) => {
        switch(section.title) {
            case 'Campus Numbers':
                return section.data.map((item, i) => renderCampusNumbers(item, i));
            case 'Residents':
                return section.data.map((item, i) => renderResident(item, i));
            case 'Team Members':
                return section.data.map((item, i) => renderTeamMember(item, i));
            default:
                return null;
        }
    }

    return (
        <div className="directory-results-container">
            {data.map((section, index) => (
                <div className="directory-section" key={index}>
                    <h2 className="section-title">{section.title}</h2>
                    {section.data.length === 0 ? (
                        <div className="no-results">No Results Found</div>
                    ) : (
                        <div className="directory-grid">
                            {renderItems(section)}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default DirectoryResultsDisplay;
