import styled from "styled-components";

const Label = styled.label`
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;

    &::before {
        content: '';
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border: 1px solid ${({ color }) => color || '#FFA500'};
        display: inline-block;
        background-color: white;
        box-sizing: border-box;
        transition: all 0.2s ease;
    }
`;

const SurveyRadio = styled.input`
    display: none;

    &:checked + ${Label}::before {
        content: '';
        background-image: ${({ color }) => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' stroke='${encodeURIComponent(color || '#FFA500')}' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E")`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px;
    }
`;

function MyCheckBox({id, name, value, onChange, label, checked, color}) {
    return (
        <div className={'survey-button-container'}>
            <SurveyRadio
                id={id}
                type={'checkbox'}
                name={name}
                value={value}
                color={color}
                onChange={onChange}
                checked={checked}
            />
            <Label htmlFor={id} color={color}>{label}</Label>
        </div>
    )
}

export default MyCheckBox
