import { useState } from "react";
import {doGet} from "../services/rest";

function PasswordReset({handleClose}) {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await doGet("login/reset?email=" + email+"&resetFrom="+ process.env.REACT_APP_REDIRECT)
        setSubmitted(true);
    };

    return (
        <div style={{ width: '350px', padding: '30px', margin: '0 auto' }}>
            <div className="login-header">
                <h2>Password Reset</h2>
            </div>

            {!submitted ? (
                <form onSubmit={handleSubmit}>
                    <div className="login-input-group">
                        <label htmlFor="email">Forgot Password</label>
                        <input
                            id="email"
                            className="login-field"
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            placeholder="Enter your email"
                            name="email"
                            value={email}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="login-button"
                    >
                        Reset Password
                    </button>
                </form>
            ) : (
                <div className="confirmation-message">
                    <p style={{ textAlign: 'center', padding: '20px 0', color: '#4a4a4a' }}>
                        Thank you! You will receive an email with further instructions on how to reset your password.
                    </p>
                    <button
                        onClick={handleClose}
                        className="login-button"
                    >
                        Close
                    </button>
                </div>
            )}
        </div>
    );
}

export default PasswordReset;
