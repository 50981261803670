import { slide as Menu } from 'react-burger-menu';
import "./../css/Sitebar.css";
import {
    faCalendar as faCalendarSolid,
    faSmile,
    faAppleAlt,
    faStethoscope,
    faPoll,
    faUserCircle,
    faFolder,
    faArrowCircleUp,
    faStar,
    faTriangleExclamation,
    faQrcode,
    faCar,
    faCakeCandles,
    faWrench,
    faFilm,
    faUserAstronaut,
    faBuilding,
    faChevronRight,
    faMessage, faCircle, faHourglass,
} from "@fortawesome/free-solid-svg-icons"; // adjust as needed for styles

import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import { faCalendar as faCalendarRegular } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auth, logOut } from "../firebase/firebase";
import { doGet, mainUrl } from "../services/rest";
import { useNavigate } from "react-router-dom";
import { faGear, faStream, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState, useRef } from 'react';

const availableIcons = {
    "fa-address-book": faCalendarRegular,
    "fa-face-smile": faSmile,
    "fa-memory": faAppleAlt,
    "fa-kit-medical": faStethoscope,
    "fa-poll": faPoll,
    "fa-user": faUserCircle,
    "fa-documents": faFolder,
    "wpforms": faWpforms,
    "arrow-circle-o-up": faArrowCircleUp,
    "star-o": faStar,
    "warning": faTriangleExclamation,
    "qrcode": faQrcode,
    "calendar": faCalendarSolid,
    "vehicle": faCar,
    "wechat": faMessage,
    "birthday-cake": faCakeCandles,
    "wrench": faWrench,
    "film": faFilm,
    "admin": faUserAstronaut,
    "business": faBuilding,
    "right": faChevronRight,
};

function Sidebar({ items }) {
    const navigate = useNavigate();

    // State for submenu management
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [subMenuItems, setSubMenuItems] = useState([]);
    const [menuAnimation, setMenuAnimation] = useState('');

    // Track submenu navigation history
    const subMenuLevel = useRef(0);
    const subMenuTree = useRef({});
    const menuHistory = useRef([]);

    const myNavigation = (item) => {
        if (item.menuItemTypes.jsRoute === '/web-links') {
            if (item.singleLink) {
                window.open(item.singleUrl, '_blank')
            } else {
                navigate(`/web-links/${item.id}/${item.title}`)
            }
        } else if (item.menuItemTypes.jsRoute === '/documents') {
            navigate(`/documents/${item.id}`)
        } else if (item.menuItemTypes.jsRoute === '/media') {
            navigate(`/media/${item.id}`)
        } else if (item.menuItemTypes.jsRoute === '/submenu') {
            if (item.children) {
                // Store current menu for history
                if (subMenuLevel.current === 0) {
                    menuHistory.current = [];
                    menuHistory.current[0] = items;
                } else {
                    menuHistory.current[subMenuLevel.current] = subMenuItems;
                }

                // Store children for this level
                subMenuTree.current[subMenuLevel.current] = item.children;
                subMenuLevel.current += 1;

                // First set submenu items
                setSubMenuItems(item.children);

                // Show submenu with the slide-in animation
                setShowSubMenu(true);
                setMenuAnimation('slide-in');

                // Clear animation class after transition completes
                setTimeout(() => {
                    setMenuAnimation('');
                }, 300);
            }
        }else if (item.menuItemTypes.jsRoute === '/view'){
            navigate(`/view/${item.id}`);
        } else if (item.menuItemTypes.jsRoute === '/magazine'){
            navigate(`/magazine/${item.issuuConnection.id}`);
        } else {
            navigate(item.menuItemTypes.jsRoute)
        }
    }

    const goBack = () => {
        // Calculate the previous level
        const newLevel = subMenuLevel.current - 1;

        // Add animation class for sliding out
        setMenuAnimation('slide-out');

        setTimeout(() => {
            subMenuLevel.current = newLevel;

            if (newLevel === 0) {
                // If going back to main menu
                setShowSubMenu(false);
            } else {
                // If going back to previous submenu
                setSubMenuItems(subMenuTree.current[newLevel - 1]);
                setMenuAnimation('slide-back-in');
                setTimeout(() => setMenuAnimation(''), 300);
            }
        }, 300);
    }

    // Create menu item component to maintain consistency
    const MenuItem = ({ icon, text, onClick, hasSubmenu = false }) => (
        <div className="menu-item-main" onClick={onClick}>
            <span className="icon-wrapper">
                <FontAwesomeIcon icon={icon} />
            </span>
            {text}
            {hasSubmenu && (
                <span className="submenu-indicator">
                    <FontAwesomeIcon icon={availableIcons.right} />
                </span>
            )}
        </div>
    );

    return (
        <Menu>
            <div style={{textAlign: 'center'}}>
                <div className="user-image" onClick={() => navigate(`/self-service`)}
                     style={{backgroundImage: `url(${mainUrl + "menu/image/" + auth.currentUser?.uid})`}}>
                </div>
                <div>{auth.currentUser?.displayName}</div>
            </div>
            <hr />

            {showSubMenu ? (
                <div className={`submenu-container ${menuAnimation}`}>
                    <MenuItem
                        icon={faArrowLeft}
                        text="Back"
                        onClick={goBack}
                    />

                    {subMenuItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            icon={availableIcons[item.icon] || faCircle}
                            text={item.title}
                            onClick={() => myNavigation(item)}
                            hasSubmenu={item.menuItemTypes.jsRoute === '/submenu'}
                        />
                    ))}
                </div>
            ) : (
                <>
                    <MenuItem
                        icon={faStream}
                        text="Stream"
                        onClick={() => navigate("/")}
                    />
                    <MenuItem
                        icon={faGear}
                        text="App Settings"
                        onClick={() => navigate("/my-settings")}
                    />

                    {items.map((item, index) => (
                        <MenuItem
                            key={index}
                            icon={availableIcons[item.icon] || faCircle}
                            text={item.title}
                            onClick={() => myNavigation(item)}
                            hasSubmenu={item.menuItemTypes.jsRoute === '/submenu'}
                        />
                    ))}

                    <MenuItem
                        icon={faHourglass}
                        text="Log Off"
                        onClick={() => logOut()}
                    />
                </>
            )}
        </Menu>
    );
}

export default Sidebar;

export { availableIcons,  };
