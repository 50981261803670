import React, { useEffect } from "react";
import { defaultColor } from "../defaults/AppConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const PopUp = props => {
    // Add keyboard event listener to close popup with Escape key
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                props.handleClose();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        // Prevent body scrolling when popup is open
        document.body.style.overflow = 'hidden';

        return () => {
            document.removeEventListener('keydown', handleEscKey);
            document.body.style.overflow = 'auto';
        };
    }, [props.handleClose]);

    // Handle click outside of popup content to close
    const handleOutsideClick = (e) => {
        if (e.target.className === 'popup-overlay') {
            props.handleClose();
        }
    };

    return (
        <div className="popup-overlay" onClick={handleOutsideClick}>
            <div className="popup-container">
                <div className="popup-header">
                    <div className="popup-close" onClick={props.handleClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <div className="popup-content">
                    {props.content}
                </div>
            </div>
        </div>
    );
};

export default PopUp;
