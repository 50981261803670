import {useEffect, useMemo, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {useNavigate, useParams} from "react-router-dom";
import {doGet} from "../../services/rest";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

function ViewHtmlComponant() {
    const {id} = useParams();
    const [user, loading] = useAuthState(auth);
    const [loading2, setLoading] = useState(true);
    const [html, setHtml] = useState({});
    const navigate = useNavigate();

    const isAdmin = useMemo(() => {
        if(loading){
            return false;
        }
        return html?.securityUsers?.some(securityUser => securityUser.id === user.uid);
    }, [html, loading, auth]);

    useEffect(() => {
        loadPages();
    }, [id])

    const loadPages = async () => {
        const html = await doGet(`html/load/${id}`)
        setHtml(html);
        setLoading(false);
    }

    if(loading2) {
        return (<>
            <Heading />
            <MySpinner />
        </>)
    }

    return <>
        <Heading title={html.title || ''} icons={isAdmin ? [<FontAwesomeIcon onClick={() => navigate(`/html/edit/${id}`)} icon={faEdit} cursor={'pointer'} />] : []} />
        <div className={'inner-container'} style={{paddingTop: 20}} dangerouslySetInnerHTML={{__html: html.html || ''}}></div>
    </>
}
export default ViewHtmlComponant;
