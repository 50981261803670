import {useEffect, useRef, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody, downloadFileFromUrlPost} from "../../services/rest";
import Select from 'react-select';
import DirectoryResultsDisplay from "./DirectoryResultsDisplay";
import PopUp from "../PopUp";
import ViewResident from "./ViewResident";
import ViewEmployee from "./ViewEmployee";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faPrint} from "@fortawesome/free-solid-svg-icons";
import {defaultColor} from "../../defaults/AppConfig";
import FileSaver from 'file-saver';

function Directory(){
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const [searchBy, setSearchBy] = useState("0")
    const [selections, setSelections] = useState([])
    const [searchLocations, setSearchLocations] = useState([])
    const [subLocations, setSubLocations] = useState([])
    const [selectedSubLocations, setSelectedSubLocations] = useState([])
    const [directoryResults, setDirectoryResults] = useState()
    const [campusNumbers, setCampusNumbers] = useState([])
    const [bottomBarNumbers, setBottomBarNumbers] = useState([])
    const [displayPopUp, setDisplayPopup] = useState(false)
    const [popUpType, setPopUpType] = useState()
    const [popUpData, setPopUpData] = useState()
    const [showPdfOptions, setShowPdfOptions] = useState(false)
    const [selectedItes, setSelectedItems] = useState([]);
    const [displayClear, setDisplayClear] = useState(false)
    const [doUpdateSearch, setDoUpdateSearch] = useState(false)

    useEffect(()=>{
        if(doUpdateSearch){
            doSearch(false);
            setDoUpdateSearch(false);
        }
    },[doUpdateSearch])

    useEffect(() =>{
        doGet("directory/search-locations").then(items => {
            let itemsList= [];
            for (let item in items.team) {
                itemsList.push({label: items.team[item], value: items.team[item] + '-t'});
            }
            let itemsListResident = [];
            for (let item in items.resident) {
                itemsListResident.push({
                    label: items.resident[item],
                    value: items.resident[item] + '-r',
                });
            }
            setSelections([
                {
                    label: 'Team Members',
                    options: itemsList,
                },
                {
                    label: 'Resident',
                    options: itemsListResident,
                },
            ]);
            setIsLoading(false)
        })
        doGet("directory/").then(loc =>{
            console.log(loc)
            setCampusNumbers(loc.filter(item => !item.bottomBar));
            setBottomBarNumbers(loc.filter(item => item.bottomBar));
        })
    },[])

    useEffect(() =>{
        if(searchLocations.length === 0){
            setSubLocations([])
        }
    },[searchLocations])

    useEffect(() => {
        if(directoryResults){
            setDisplayClear(true)
        }else{
            setDisplayClear(false)
        }
    }, [directoryResults]);

    const pdfDirectory = () =>{
        if(displayClear){
            return ([
                <div style={{cursor: "pointer"}} onClick={() => setShowPdfOptions(true)}>
                    <FontAwesomeIcon icon={faPrint}/>
                </div>,
                <div style={{cursor: "pointer", paddingLeft: 10}} onClick={() => setDirectoryResults(undefined)}>
                    <FontAwesomeIcon icon={faClose} />
                </div>
            ])
        }
        return ([<div style={{cursor: "pointer"}} onClick={() => setShowPdfOptions(true)}>
            <FontAwesomeIcon icon={faPrint} />
        </div>])
    }

    useEffect(() => {
        const loadLocations = async () => {
            console.log(searchLocations)
            if(searchLocations.length > 0){
                const search = []
                for(const i in searchLocations){
                    search.push(searchLocations[i].value)
                }
                const resp = await doPostBody('directory/get-sub-locations', search)

                const transformedData = resp.map(dataElem =>
                    ({
                        label: dataElem.name,
                        options: dataElem.children.map(child => (
                            {
                                value: child.id,
                                label: child.name
                            }
                        ))
                    })
                );
                setSubLocations(transformedData)
                setIsLoading(false)
            }else{
                setSubLocations([])
            }
        }
        loadLocations()
    }, [searchLocations])

    const downloadPdf = async (location) => {
        if (location !== 'Campus') {
            if (
                location === 'Team Members' &&
                !selectedItes.some(item => item.endsWith('-t'))
            ) {
                window.alert(
                    'Please select at least one item from Team Members.',
                );
                return;
            } else if (
                location === 'Resident' &&
                !selectedItes.some(item => item.endsWith('-r'))
            ) {
                window.alert(
                    'Please select at least one item from Resident Locations.',
                );
                return;
            }
        }
        setIsLoading(true);
        downloadFileFromUrlPost({url: 'directory/pdf/'+location, data: selectedItes}).then(blob =>{
                FileSaver.saveAs(new Blob([blob]), location+".pdf")
            }
        )
        setIsLoading(false)
    };

    const customStyles = {
        control: provided => ({
            ...provided,
            width: '100%',
            padding: '5px',
            fontSize: '16px',
            borderRadius: '8px',
            borderColor: '#e0e0e0',
            boxShadow: 'none',
            '&:hover': { borderColor: defaultColor }
        }),
        input: provided => ({
            ...provided,
            color: '#000'
        }),
        singleValue: provided => ({
            ...provided,
            color: '#000'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? defaultColor : state.isFocused ? `${defaultColor}20` : null,
            color: state.isSelected ? 'white' : '#333',
            '&:hover': {
                backgroundColor: `${defaultColor}20`
            }
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: `${defaultColor}20`,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#333',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#666',
            '&:hover': {
                backgroundColor: defaultColor,
                color: 'white',
            },
        }),
    };

    const addSelectedItems = (location) => {
        const selected = [...selectedItes] ?? [];
        const index = selected.findIndex(item => item === location);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(location);
        }
        setSelectedItems(selected);
    };

    const showChecked = (item)  => {
        return selectedItes.includes(item);
    };

    const doSearch = async (showLoading) =>{
        if(showLoading){
            setIsLoading(true)
        }
        const searchData = {search: search, searchType: searchBy}
        if(searchLocations.length > 0){
            const items = [];
            for(const i in searchLocations){
                items.push(searchLocations[i].value)
            }
            const employee = [];
            const resident = [];
            for (let i = 0; items.length > i; i++) {
                const myString = items[i];
                if (myString.endsWith('-t')) {
                    employee.push(myString.substring(0, myString.length - 2));
                } else {
                    resident.push(myString.substring(0, myString.length - 2));
                }
            }
            if (employee.length > 0) {
                searchData.employeeLocations = employee;
            }
            if (resident.length > 0) {
                searchData.residentLocations = resident;
            }
            if (selectedSubLocations.length > 0) {
                const newList = [];
                for(let i in selectedSubLocations){
                    newList.push(selectedSubLocations[i].value)
                }
                searchData.subLocations = newList;
            }
        }
        const resp = await doPostBody(
            'directory/search',
            searchData,
        );
        setDirectoryResults(resp)
        setIsLoading(false)
    }

    if(isLoading){
        return (<>
            <Heading title={"Directory"} />
            <MySpinner />
        </>)
    }

    const onTextChange = event =>{
        setSearch(event.target.value)
    }

    const onReturn = event =>{
        if (event.key === 'Enter') {
            doSearch(true)
        }
    }

    const handleSelection = (data, title) =>{
        if(title === "Campus Numbers"){
            return;
        }
        setPopUpType(title)
        setPopUpData(data)
        setDisplayPopup(true)
    }

    const onSelectItemsChangeTeam = (items) => {
        setSearchLocations(items);
        if(directoryResults){
            setDoUpdateSearch(true);
        }
    }

    const onSelectedItemsChangeAdditional = (items) => {
        setSelectedSubLocations(items);
        if(directoryResults){
            setDoUpdateSearch(true);
        }
    }

    return (<>
        <Heading title={"Directory"} icons={pdfDirectory()}/>
        <div className="directory-container">
            <div className="search-wrapper">
                <div className="search-container">
                    <input
                        className="search-input"
                        type="text"
                        placeholder="Type Here..."
                        value={search}
                        onChange={onTextChange}
                        onKeyDown={onReturn}
                    />
                </div>
                <div className="filter-container">
                    <div className="filter-option">
                        <input id="all" className="directory-radio" name="searchBy" type="radio"
                               value="ALL" checked={searchBy === '0'}
                               onClick={() => setSearchBy('0')}/>
                        <label htmlFor="all" className="directory-radio-label">All</label>
                    </div>
                    <div className="filter-option">
                        <input id="resident" className="directory-radio" name="searchBy" type="radio"
                               value="RESIDENT" checked={searchBy === '1'}
                               onClick={() => setSearchBy('1')}/>
                        <label htmlFor="resident" className="directory-radio-label">Resident</label>
                    </div>
                    <div className="filter-option">
                        <input id="team" className="directory-radio" name="searchBy" type="radio"
                               value="TEAMEMMBER" checked={searchBy === '2'}
                               onClick={() => setSearchBy('2')}/>
                        <label htmlFor="team" className="directory-radio-label">Team Member</label>
                    </div>
                    <div className="filter-option">
                        <input id="campus" className="directory-radio" name="searchBy" type="radio"
                               value="CAMPUS" checked={searchBy === '3'}
                               onClick={() => setSearchBy('3')}/>
                        <label htmlFor="campus" className="directory-radio-label">Campus</label>
                    </div>
                </div>
            </div>
            <div className="select-wrapper">
                <Select
                    placeholder="Select Search Locations"
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={onSelectItemsChangeTeam}
                    defaultValue={searchLocations}
                    options={selections}
                    styles={customStyles}
                    className="directory-select"
                />

                {subLocations.length > 0 &&
                    <Select
                        placeholder="Select"
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={onSelectedItemsChangeAdditional}
                        defaultValue={selectedSubLocations}
                        options={subLocations}
                        styles={customStyles}
                        className="directory-select"
                    />
                }

                <button className="directory-search-button" onClick={() => doSearch(true)}>
                    Search
                </button>
            </div>

            {directoryResults &&
                <div className="directory-results">
                    <DirectoryResultsDisplay
                        directoryResponse={directoryResults}
                        displayType={searchBy}
                        onClick={handleSelection}
                    />
                </div>
            }

            {!directoryResults && !isLoading && (
                <div className="emergency-numbers">
                    {campusNumbers.map((numbers, index) => (
                        <a
                            key={index}
                            className="emergency-number-card"
                            href={`tel:${numbers.emergencyNumber}`}
                        >
                            <div className="emergency-name">{numbers.emergencyName}</div>
                            <div className="emergency-number">{numbers.emergencyNumber}</div>
                        </a>
                    ))}
                </div>
            )}
        </div>

        {!directoryResults && !isLoading && (
            <div className="bottom-bar">
                {bottomBarNumbers.map((numbers, index) => (
                    <a
                        key={index}
                        className="bottom-bar-number"
                        href={`tel:${numbers.emergencyNumber}`}
                    >
                        <div>{numbers.emergencyName}</div>
                        <div>{numbers.emergencyNumber}</div>
                    </a>
                ))}
            </div>
        )}

        {displayPopUp &&
            <PopUp
                content={
                    <>
                        {popUpType === 'Residents'
                            ? <ViewResident resident={popUpData} />
                            : <ViewEmployee employee={popUpData} />
                        }
                    </>
                }
                handleClose={() => setDisplayPopup(false)}
            />
        }

        {showPdfOptions &&
            <PopUp
                content={
                    <div className="pdf-options">
                        {selections.map((loc, locIndex) => (
                            <div key={locIndex} className="pdf-section">
                                <div className="pdf-section-title">{loc.label}</div>
                                <div className="pdf-checkboxes">
                                    {loc.options.map((item, itemIndex) => (
                                        <div key={itemIndex} className="pdf-checkbox-container">
                                            <input
                                                className="pdf-checkbox"
                                                type="checkbox"
                                                id={item.value}
                                                checked={showChecked(item.value)}
                                                onClick={() => addSelectedItems(item.value)}
                                            />
                                            <label className="pdf-checkbox-label" htmlFor={item.value}>
                                                {item.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    onClick={() => downloadPdf(loc.label)}
                                    className="pdf-generate-button"
                                >
                                    Generate Printable {loc.label} PDF
                                </button>
                            </div>
                        ))}
                        <div className="pdf-campus-section">
                            <button
                                onClick={() => downloadPdf('Campus')}
                                className="pdf-generate-button"
                            >
                                Generate Printable Campus PDF
                            </button>
                        </div>
                    </div>
                }
                handleClose={() => setShowPdfOptions(false)}
            />
        }
    </>)
}

export default Directory
