import Heading from "../Heading";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faCheck, faFile, faGripLines, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useDropzone} from "react-dropzone";
import {doGet, doPost, doPostBody, downloadFileFromUrlPost, uploadDocuments} from "../../services/rest";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {defaultColor} from "../../defaults/AppConfig";
import FileSaver from 'file-saver';
import {useNavigate, useParams} from "react-router-dom";
import MySpinner from "../MySpinner";

function DocumentsRepo({catie}){
    const {id,'*': folder} = useParams();
    const [folderAdmin, setFolderAdmin] = useState(false)
    const [dragAndDropActive, setDrageAndDropActive] = useState(false)
    const [documents, setDocuments] = useState({});
    const [loading, setLoading] = useState(true);
    const [reorderMode, setReorderMode] = useState(false);
    const [documentOrder, setDocumentOrder] = useState([]);
    const [folderOrder, setFolderOrder] = useState([]);
    const [folderIdMap, setFolderIdMap] = useState({}); // Map folder paths to IDs
    const [orderChanged, setOrderChanged] = useState(false);
    const [draggedItem, setDraggedItem] = useState(null);
    const [draggedOverItem, setDraggedOverItem] = useState(null);
    const [dragType, setDragType] = useState(null); // 'document' or 'folder'
    const itemRefs = useRef({});
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const title = queryParams.get('title'); // "John"
    useEffect(() => {
        let url = `documents/${id}?folder=${folder}`
        if(!folder){
            url = `documents/${id}`
        }
        doGet(url).then(res => {
            setDocuments(res)
            if (res.documents) {
                setDocumentOrder(res.documents.map(doc => doc.id));
            }
            if (res.folders) {
                setFolderOrder(res.folders);

                // If folders include ID information, store it in a map
                if (res.folderIds) {
                    const idMap = {};
                    res.folderIds.forEach((folderInfo, index) => {
                        idMap[res.folders[index]] = folderInfo.id;
                    });
                    setFolderIdMap(idMap);
                }
            }
            setLoading(false)
        })
    },[id, folder])

    const onFolderChange = async (newFolder) => {
        if(folder === newFolder){
            navigate(0)
        }else{
            if(catie){
                if(title){
                    window.location.href = `/catie/documents/${id}/${newFolder}?title=${title}`;
                }else{
                    window.location.href`/catie/documents/${id}/${newFolder}`;
                }


            }else{
                navigate(`/documents/${id}/${newFolder}`)
            }

        }
    }

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData();
        acceptedFiles.forEach((file) =>{
            formData.append(`file`, file)
        });

        if(folder){
            formData.append("location", folder)
        }
        formData.append("repositoryId", id)
        uploadDocuments(formData).then(resp => onFolderChange(folder))
    }, [folder, id])

    const {getRootProps, getInputProps, isDragActive,isFocused,
        isDragAccept,
        isDragReject} = useDropzone({onDrop})

    useEffect(() => {
        setFolderAdmin(hasAdmin("ROLE_DOCUMENTS"))
    }, []);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const generateIcons = () => {
        if(folderAdmin){
            const icons = [<div key="add" onClick={() => setDrageAndDropActive(!dragAndDropActive)} style={{cursor: "pointer"}}><FontAwesomeIcon icon={faAdd} /></div>];

            // Add reorder toggle button if there are documents or folders to reorder
            if ((documents.documents && documents.documents.length > 1) || (documents.folders && documents.folders.length > 1)) {
                if (reorderMode) {
                    icons.push(
                        <div key="save" onClick={saveOrder} style={{cursor: "pointer", marginLeft: "15px", color: orderChanged ? "#4CAF50" : "#ccc"}}>
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                    );
                    icons.push(
                        <div key="cancel" onClick={() => {
                            setReorderMode(false);
                            // Reset the documents and folders to their original order by reloading
                            if (orderChanged) {
                                onFolderChange(folder);
                            }
                        }} style={{cursor: "pointer", marginLeft: "15px", color: "#F44336"}}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    );
                } else {
                    icons.push(
                        <div key="reorder" onClick={() => setReorderMode(true)} style={{cursor: "pointer", marginLeft: "15px"}}>
                            <span style={{fontSize: "14px", display: "flex", alignItems: "center"}}>
                                <FontAwesomeIcon icon={faGripLines} style={{marginRight: "5px"}} />
                            </span>
                        </div>
                    );
                }
            }

            return icons;
        }
        return []
    }

    const downloadFile = (file) => {
            downloadFileFromUrlPost({ url: 'documents/download', data: file })
                .then(blob => {
                    // Determine the MIME type based on file extension
                    const fileExtension = file.title.split('.').pop().toLowerCase();
                    let mimeType = 'application/octet-stream'; // Default MIME type

                    // Map common file extensions to MIME types
                    const mimeTypes = {
                        'pdf': 'application/pdf',
                        'doc': 'application/msword',
                        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'xls': 'application/vnd.ms-excel',
                        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'ppt': 'application/vnd.ms-powerpoint',
                        'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                        'txt': 'text/plain',
                        'csv': 'text/csv',
                        'png': 'image/png',
                        'jpg': 'image/jpeg',
                        'jpeg': 'image/jpeg',
                        'gif': 'image/gif',
                        'svg': 'image/svg+xml',
                        'html': 'text/html',
                        'xml': 'application/xml',
                        'json': 'application/json'
                    };

                    if (mimeTypes[fileExtension]) {
                        mimeType = mimeTypes[fileExtension];
                    }

                    // Create a blob with the correct MIME type
                    const typedBlob = new Blob([blob], { type: mimeType });
                    const blobUrl = URL.createObjectURL(typedBlob);

                    // Open the file in a new browser tab
                    if(catie){
                        window.location.href = blobUrl;
                    }else{
                        window.open(blobUrl, '_blank');
                    }
                    // Clean up the blob URL when it's no longer needed
                    // This timeout is to ensure the browser has time to open the URL
                    setTimeout(() => {
                        URL.revokeObjectURL(blobUrl);
                    }, 1000);
                });
    }

    const deleteDocument = (document) =>{
        if(window.confirm("Are you sure you want to delete this document?")){
            doPostBody("documents/admin/delete", document).then(res => {
                if(res){
                    onFolderChange(folder)
                }
            })
        }
    }

    const deleteFolder = (folder1) =>{
        if(window.confirm("Are you sure you want to delete this folder?  It will delete the folder and all of the contents.")){
            const formdata = new FormData();
            formdata.append("folder", folder1)
            formdata.append("repo", id)
            doPost("documents/admin/delete/folder",formdata).then(res => {
                if(res){
                    onFolderChange(folder)
                }
            })
        }
    }

    const addFolder = () => {
        const newFolder = window.prompt("Enter a new folder name.");
        if(newFolder){
            if(folder){
                addFolderDatabaseReference(`${folder}/${newFolder}`)
                onFolderChange(`${folder}/${newFolder}`)
            }else{
                addFolderDatabaseReference(`/${newFolder}`)
                onFolderChange(`/${newFolder}`)
            }
        }
    }

    const addFolderDatabaseReference = (folderReference) => {
        doGet(`documents/admin/folder-reference/${id}?folderName=${folderReference}`)
    }

    // Handle drag start for both documents and folders
    const handleDragStart = (e, item, type) => {
        e.dataTransfer.effectAllowed = "move";
        setDraggedItem(item);
        setDragType(type);

        // Add a delay to prevent the drag image from being blank
        setTimeout(() => {
            const key = type === 'document' ? item.id : item;
            if (itemRefs.current[`${type}-${key}`]) {
                itemRefs.current[`${type}-${key}`].style.opacity = "0.4";
            }
        }, 0);

        // Set a custom drag image
        const dragImage = document.createElement('div');
        dragImage.className = 'item-drag-image';
        dragImage.textContent = type === 'document' ? item.title : item.split("/").pop();
        dragImage.style.position = 'absolute';
        dragImage.style.top = '-1000px';
        dragImage.style.padding = '8px';
        dragImage.style.background = '#f0f0f0';
        dragImage.style.border = '1px solid #ccc';
        dragImage.style.borderRadius = '4px';
        document.body.appendChild(dragImage);
        e.dataTransfer.setDragImage(dragImage, 0, 0);

        // Clean up the temporary element
        setTimeout(() => {
            document.body.removeChild(dragImage);
        }, 0);
    };

    // Handle drag over for both documents and folders
    const handleDragOver = (e, item, type) => {
        e.preventDefault();
        // Only set draggedOverItem if it's the same type as what's being dragged
        if (type === dragType) {
            setDraggedOverItem(item);
        }
    };

    // Handle drag end for both documents and folders
    const handleDragEnd = (e) => {
        e.preventDefault();

        // Reset opacity
        if (draggedItem) {
            const key = dragType === 'document' ? draggedItem.id : draggedItem;
            if (itemRefs.current[`${dragType}-${key}`]) {
                itemRefs.current[`${dragType}-${key}`].style.opacity = "1";
            }
        }

        // If we have both items and they're different, update the order
        if (draggedItem && draggedOverItem && dragType) {
            if (dragType === 'document') {
                // Handle document reordering
                if (draggedItem.id !== draggedOverItem.id) {
                    // Create a copy of the current documents array
                    const items = Array.from(documents.documents);

                    // Find the indices
                    const draggedIndex = items.findIndex(item => item.id === draggedItem.id);
                    const draggedOverIndex = items.findIndex(item => item.id === draggedOverItem.id);

                    if (draggedIndex !== -1 && draggedOverIndex !== -1) {
                        // Remove the dragged item
                        const [removedItem] = items.splice(draggedIndex, 1);

                        // Add it at the new position
                        items.splice(draggedOverIndex, 0, removedItem);

                        // Update the state
                        setDocuments({...documents, documents: items});
                        setDocumentOrder(items.map(doc => doc.id));
                        setOrderChanged(true);
                    }
                }
            } else if (dragType === 'folder') {
                // Handle folder reordering
                if (draggedItem !== draggedOverItem) {
                    // Create a copy of the current folders array
                    const items = Array.from(documents.folders);

                    // Find the indices
                    const draggedIndex = items.indexOf(draggedItem);
                    const draggedOverIndex = items.indexOf(draggedOverItem);

                    if (draggedIndex !== -1 && draggedOverIndex !== -1) {
                        // Remove the dragged item
                        const [removedItem] = items.splice(draggedIndex, 1);

                        // Add it at the new position
                        items.splice(draggedOverIndex, 0, removedItem);

                        // Update the state
                        setDocuments({...documents, folders: items});
                        setFolderOrder(items);
                        setOrderChanged(true);
                    }
                }
            }
        }

        // Reset drag items
        setDraggedItem(null);
        setDraggedOverItem(null);
        setDragType(null);
    };

    // Save the new order for both documents and folders
    const saveOrder = async () => {
        if (!orderChanged) return;

        setLoading(true);

        // Save document order
        if (documents.documents && documents.documents.length > 0) {
            const documentOrderItems = documentOrder.map((docId, index) => ({
                id: docId,
                position: index
            }));

            let folderUrl = `documents/admin/update-order/${id}?folder=${folder}`
            if(!folder){
                folderUrl = `documents/admin/update-order/${id}`
            }

            await doPostBody(folderUrl, documentOrderItems);
        }

        // Save folder order
        if (documents.folders && documents.folders.length > 0) {
            // Use numeric folder IDs if available, otherwise use path
            console.log(folderOrder)
            const folderOrderItems = folderOrder.map((folderPath, index) => {
                const folderId = folderIdMap[folderPath] || folderPath;
                return {
                    folder: folderId,
                    position: index
                };
            });

            let folderUrl = `documents/admin/update-folder-order/${id}?folder=${folder}`
            if(!folder){
                folderUrl = `documents/admin/update-folder-order/${id}`
            }

            await doPostBody(folderUrl, folderOrderItems);
        }

        // Reload the updated data
        let url = `documents/${id}?folder=${folder}`
        if(!folder){
            url = `documents/${id}`
        }
        const res = await doGet(url);
        setDocuments(res);

        setLoading(false);
        setReorderMode(false);
        setOrderChanged(false);
    };

    if(loading){
        return(<>
            <Heading title={'Documents'} />
            <MySpinner />
        </>)
    }

    if(catie){
        return  <>
            <Heading title={title ?? 'Documents Repository'} />
            <div className="documents-gallery-container">
                {/* Display Folders */}
                {documents.folders && documents.folders.length > 0 && (
                    <div className="gallery-section">
                        <h3 className="gallery-section-title">Folders</h3>
                        <div className="gallery-grid">
                            {documents.folders.map(folderPath => (
                                <div
                                    key={folderPath}
                                    className="gallery-item folder-item"
                                    onClick={() => onFolderChange(folderPath)}
                                >
                                    <div className="item-icon-container">
                                        <FontAwesomeIcon
                                            icon={faFolder}
                                            className="item-icon folder-icon"
                                            style={{ color: defaultColor }}
                                        />
                                    </div>
                                    <div className="item-name">
                                        {folderPath.split("/").pop()}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Display Documents */}
                {documents.documents && documents.documents.length > 0 && (
                    <div className="gallery-section">
                        <h3 className="gallery-section-title">Documents</h3>
                        <div className="gallery-grid">
                            {documents.documents.map(document => (
                                <div
                                    key={document.id}
                                    className="gallery-item document-item"
                                    onClick={() => downloadFile(document)}
                                >
                                    <div className="item-icon-container">
                                        <FontAwesomeIcon
                                            icon={faFile}
                                            className="item-icon document-icon"
                                            style={{ color: defaultColor }}
                                        />
                                    </div>
                                    <div className="item-name">
                                        {document.title}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Display a message if no content */}
                {(!documents.folders || documents.folders.length === 0) &&
                    (!documents.documents || documents.documents.length === 0) && (
                        <div className="no-content-message">
                            No folders or documents found in this location.
                        </div>
                    )
                }
            </div>
        </>
    }

    return(<>
        <Heading title={'Documents Repository'} icons={generateIcons()}/>
        {dragAndDropActive && <div className="container">
            <div onClick={addFolder} style={{padding: 30, cursor: 'pointer'}}>Add Folder</div>
            <div {...getRootProps()}>
                <input {...getInputProps({style})} />
                {isDragActive ? <p>Drop files or folder here...</p> :
                    <p>Drag 'n' drop some files or folders here, or click to select files.</p>
                }
            </div></div>
        }
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {/* Folders Section */}
            {documents.folders && documents.folders.length > 0 && (
                <div className="folders-section" style={{marginBottom: '30px'}}>
                    {reorderMode && folderAdmin ? (
                        <div
                            style={{
                                width: '100%',
                                padding: '8px',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '4px',
                                marginBottom: '20px'
                            }}
                        >
                            {documents.folders.map((folderPath, index) => (
                                <div
                                    key={folderPath}
                                    ref={el => itemRefs.current[`folder-${folderPath}`] = el}
                                    draggable={true}
                                    onDragStart={(e) => handleDragStart(e, folderPath, 'folder')}
                                    onDragOver={(e) => handleDragOver(e, folderPath, 'folder')}
                                    onDragEnd={handleDragEnd}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '12px',
                                        marginBottom: '8px',
                                        backgroundColor: draggedOverItem === folderPath && dragType === 'folder' ? '#e0e0e0' : 'white',
                                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '4px',
                                        transition: 'background-color 0.2s, box-shadow 0.2s',
                                        cursor: 'move'
                                    }}
                                >
                                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                        <FontAwesomeIcon icon={faGripLines} style={{marginRight: '15px', color: '#999'}} />
                                        <FontAwesomeIcon icon={faFolder} style={{color: defaultColor, paddingRight:20, fontSize:20}} />
                                        {folderPath.split("/").pop()}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {documents.folders.map(folderPath => (
                                <div key={folderPath} style={{width: '100%', display: 'flex', flexDirection:'row', paddingBottom: 30, cursor: 'pointer'}}>
                                    <div style={{display: 'flex', flexDirection:'row', cursor: 'pointer', flexGrow: 1, width: '100%'}} onClick={() => onFolderChange(folderPath)}>
                                        <FontAwesomeIcon style={{color: defaultColor, paddingRight:20, fontSize:20}} icon={faFolder} />
                                        {folderPath.split("/").pop()}
                                    </div>
                                    {folderAdmin && <div onClick={() => deleteFolder(folderPath)} style={{display: 'flex', flexDirection: 'row', justifyContent: "flex-end", flexShrink: 1}}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>}
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}

            {/* Documents Section */}
            {documents.documents && documents.documents.length > 0 && (
                <div className="documents-section">
                    {reorderMode && folderAdmin ? (
                        <div
                            style={{
                                width: '100%',
                                padding: '8px',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '4px'
                            }}
                        >
                            {documents.documents.map((document, index) => (
                                <div
                                    key={document.id}
                                    ref={el => itemRefs.current[`document-${document.id}`] = el}
                                    draggable={true}
                                    onDragStart={(e) => handleDragStart(e, document, 'document')}
                                    onDragOver={(e) => handleDragOver(e, document, 'document')}
                                    onDragEnd={handleDragEnd}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '12px',
                                        marginBottom: '8px',
                                        backgroundColor: draggedOverItem && draggedOverItem.id === document.id && dragType === 'document' ? '#e0e0e0' : 'white',
                                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '4px',
                                        transition: 'background-color 0.2s, box-shadow 0.2s',
                                        cursor: 'move'
                                    }}
                                >
                                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                        <FontAwesomeIcon icon={faGripLines} style={{marginRight: '15px', color: '#999'}} />
                                        <FontAwesomeIcon icon={faFile} style={{color: defaultColor, paddingRight:20, fontSize:20}} />
                                        {document.title}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {documents.documents.map(document => (
                                <div key={document.id} style={{width: '100%', display: 'flex', flexDirection:'row', paddingBottom: 30, cursor: 'pointer'}}>
                                    <div style={{width: '100%', display: 'flex', flexDirection:'row', cursor: 'pointer'}} onClick={() => downloadFile(document)}>
                                        <FontAwesomeIcon icon={faFile} style={{color: defaultColor, paddingRight:20, fontSize:20}} />
                                        {document.title}
                                    </div>
                                    {folderAdmin && <div onClick={() => deleteDocument(document)} style={{display: 'flex', flexDirection: 'row', justifyContent: "flex-end"}}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>}
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}

            {/* Display a message if no content */}
            {(!documents.folders || documents.folders.length === 0) &&
                (!documents.documents || documents.documents.length === 0) && (
                    <div style={{textAlign: 'center', padding: '30px 0', color: '#666'}}>
                        No folders or documents found. Use the + button above to add content.
                    </div>
                )}
        </div>
    </>)
}

export default DocumentsRepo;
