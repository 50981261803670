import Heading from "./Heading";
import React, {useEffect, useState} from "react";
import MySpinner from "./MySpinner";
import {doGet} from "../services/rest";
import {defaultColor} from "../defaults/AppConfig";
import ResidentImages from "./directory/ResidentImages";
import {formatFirstNameLastName} from "./directory/NameFormatters";
import PopUp from "./PopUp";
import ViewResident from "./directory/ViewResident";
import moment from "moment";
import ResidentImageSingle from "./directory/ResidentImageSingle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faCalendarAlt, faBirthdayCake, faHeart} from "@fortawesome/free-solid-svg-icons";

function Celebrations() {
    const [loading, setLoading] = useState(true);
    const [birthday, setBirthday] = useState([]);
    const [newResidents, setNewResidents] = useState([]);
    const [deaths, setDeaths] = useState([]);
    const [startDateBirthday, setStartDateBirthday] = useState();
    const [startDateNew, setStartDateNew] = useState();
    const [startDateDeath, setStartDateDeath] = useState();
    const [selected, setSelected] = useState('new');
    const [newResidentPopup, setNewResidentPopup] = useState();
    const [viewDeathInfo, setViewDeathInfo] = useState();

    useEffect(() => {
        doGet('celebrations').then(resp => {
            setBirthday(resp.birthday)
            setNewResidents(resp.new)
            setDeaths(resp.death)
            const now = new Date();
            now.setDate(1);
            setStartDateBirthday(now)
            setStartDateDeath(now)
            setStartDateNew(now)
            setLoading(false)
        })
    }, []);

    if(loading){
        return(<>
            <Heading title={'Celebrations'} />
            <MySpinner />
        </>)
    }

    const forwardTitle = () => {
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() + 1)
                return moment(newDate).format('MMMM YYYY');
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() + 1)
                return moment(newDate1).format('MMMM YYYY');
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() + 1)
                return moment(newDate2).format('MMMM YYYY');
            default:
                return '';
        }
    }

    const backTitle = () => {
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() - 1)
                return moment(newDate).format('MMMM YYYY');
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() - 1)
                return moment(newDate1).format('MMMM YYYY');
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() - 1)
                return moment(newDate2).format('MMMM YYYY');
            default:
                return '';
        }
    }

    const loadResidends = (newDate) => {
        setLoading(true)
        let endDate = new Date(newDate)
        endDate.setMonth(endDate.getMonth() + 1)
        endDate.setDate(0)
        doGet(`celebrations/new-residents?startDate=${moment(newDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`).then(resp =>{
            setNewResidents(resp)
            setLoading(false)
        })
    }

    const birthdayByMonth = (newDate) => {
        setLoading(true)
        doGet(`celebrations/birthdays?month=${newDate.getMonth() +1}`).then(resp =>{
            setBirthday(resp)
            setLoading(false)
        })
    }

    const deceasedResidents = (newDate) =>{
        setLoading(true)
        doGet(`celebrations/celebrate-life?date=${moment(newDate).format("YYYY-MM-DD")}`).then(resp =>{
            setDeaths(resp)
            setLoading(false)
        })
    }

    const goBack = () =>{
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() - 1)
                setStartDateNew(newDate)
                loadResidends(newDate)
                break;
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() - 1)
                setStartDateBirthday(newDate1)
                birthdayByMonth(newDate1)
                break;
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() - 1)
                setStartDateDeath(newDate2)
                deceasedResidents(newDate2)
                break;
            default:
                break;
        }
    }

    const goForward = () =>{
        switch (selected){
            case "new":
                const newDate = new Date(startDateNew)
                newDate.setMonth(newDate.getMonth() + 1)
                setStartDateNew(newDate)
                loadResidends(newDate)
                break;
            case "birthday":
                const newDate1 = new Date(startDateBirthday)
                newDate1.setMonth(newDate1.getMonth() + 1)
                setStartDateBirthday(newDate1)
                birthdayByMonth(newDate1)
                break;
            case "death":
                const newDate2 = new Date(startDateDeath)
                newDate2.setMonth(newDate2.getMonth() + 1)
                setStartDateDeath(newDate2)
                deceasedResidents(newDate2)
                break;
            default:
                break;
        }
    }

    const getCurrentMonthTitle = () => {
        switch (selected) {
            case 'new':
                return moment(startDateNew).format("MMMM YYYY");
            case 'birthday':
                return moment(startDateBirthday).format("MMMM YYYY");
            case 'death':
                return moment(startDateDeath).format("MMMM YYYY");
            default:
                return '';
        }
    }

    const getCategoryIcon = () => {
        switch (selected) {
            case 'new':
                return <FontAwesomeIcon icon={faCalendarAlt} />;
            case 'birthday':
                return <FontAwesomeIcon icon={faBirthdayCake} />;
            case 'death':
                return <FontAwesomeIcon icon={faHeart} />;
            default:
                return null;
        }
    }

    return(<>
        <Heading title={'Celebrations'}/>
        <div style={styles.container}>
            <div style={styles.tabContainer}>
                <div
                    onClick={() => setSelected('new')}
                    style={selected === 'new' ? styles.selectedTab : styles.tab}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} style={styles.tabIcon} />
                    <span style={styles.tabText}>New Residents</span>
                </div>
                <div
                    onClick={() => setSelected('birthday')}
                    style={selected === 'birthday' ? styles.selectedTab : styles.tab}
                >
                    <FontAwesomeIcon icon={faBirthdayCake} style={styles.tabIcon} />
                    <span style={styles.tabText}>Resident Birthdays</span>
                </div>
                <div
                    onClick={() => setSelected('death')}
                    style={selected === 'death' ? styles.selectedTab : styles.tab}
                >
                    <FontAwesomeIcon icon={faHeart} style={styles.tabIcon} />
                    <span style={styles.tabText}>Celebrate a Life</span>
                </div>
            </div>

            <div style={styles.monthHeader}>
                <div style={styles.monthTitle}>
                    {getCategoryIcon()}
                    <h2 style={styles.monthTitleText}>{getCurrentMonthTitle()}</h2>
                </div>
            </div>

            <div style={styles.cardsContainer}>
                {selected === 'new' && (
                    newResidents.length > 0 ? (
                        <div style={styles.cardsGrid}>
                            {newResidents.map((item, index) => (
                                <div
                                    onClick={() => setNewResidentPopup(item)}
                                    key={index}
                                    style={styles.card}
                                >
                                    <div style={styles.cardImageContainer}>
                                        <ResidentImages resident={item} thumb={true}/>
                                    </div>
                                    <div style={styles.cardContent}>
                                        <h3 style={styles.cardTitle}>{formatFirstNameLastName(item.residentsNew)}</h3>
                                        <div style={styles.cardDetail}>{item.homeNumber} {item.address}</div>
                                        {item?.neighborhood?.neighborHoodCommunityName && (
                                            <div style={styles.cardNeighborhood}>
                                                {item.neighborhood.neighborHoodCommunityName}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={styles.emptyState}>
                            No new residents for this month
                        </div>
                    )
                )}

                {selected === 'birthday' && (
                    birthday.length > 0 ? (
                        <div style={styles.cardsGrid}>
                            {birthday.map((item, index) => (
                                <div key={index} style={styles.card}>
                                    <div style={styles.cardImageContainer}>
                                        <ResidentImageSingle resident={item} thumb={true}/>
                                    </div>
                                    <div style={styles.cardContent}>
                                        <div style={styles.birthdayDate}>
                                            {moment(item.residentBirthdate).format("MMM DD")}
                                        </div>
                                        <h3 style={styles.cardTitle}>{item.firstName} {item.lastName}</h3>
                                        {item?.residentLocations?.neighborhood?.neighborHoodCommunityName && (
                                            <div style={styles.cardNeighborhood}>
                                                {item.residentLocations.neighborhood.neighborHoodCommunityName}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={styles.emptyState}>
                            No birthdays for this month
                        </div>
                    )
                )}

                {selected === 'death' && (
                    deaths.length > 0 ? (
                        <div style={styles.cardsGrid}>
                            {deaths.map((item, index) => (
                                <div
                                    onClick={() => setViewDeathInfo(item)}
                                    key={index}
                                    style={styles.card}
                                >
                                    <div style={styles.cardImageContainer}>
                                        <ResidentImageSingle resident={item} thumb={true}/>
                                    </div>
                                    <div style={styles.cardContent}>
                                        <h3 style={styles.cardTitle}>{item.fname} {item.lname}</h3>
                                        <div style={styles.cardDetail}>
                                            {item.residentLocations.homeNumber} {item.residentLocations.address}
                                        </div>
                                        {item?.residentLocations?.neighborhood?.neighborHoodCommunityName && (
                                            <div style={styles.cardNeighborhood}>
                                                {item.residentLocations.neighborhood.neighborHoodCommunityName}
                                            </div>
                                        )}
                                        <div style={styles.dateRange}>
                                            {moment(item.residentBirth).format("MM/DD/YYYY")} - {moment(item.datePassed).format("MM/DD/YYYY")}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={styles.emptyState}>
                            No memorial services for this month
                        </div>
                    )
                )}
            </div>
        </div>

        {newResidentPopup && (
            <PopUp
                content={<ViewResident resident={newResidentPopup}/>}
                handleClose={() => setNewResidentPopup(undefined)}
            />
        )}

        {viewDeathInfo && (
            <PopUp
                content={
                    <div style={styles.memorialPopup}>
                        <div style={styles.memorialImageContainer}>
                            <ResidentImageSingle resident={viewDeathInfo}/>
                        </div>
                        <h2 style={styles.memorialName}>{viewDeathInfo.fname} {viewDeathInfo.lname}</h2>
                        <div style={styles.memorialDetails}>
                            <div style={styles.memorialAddress}>
                                {viewDeathInfo.residentLocations.homeNumber} {viewDeathInfo.residentLocations.address}
                            </div>
                            {viewDeathInfo?.residentLocations?.neighborhood?.neighborHoodCommunityName && (
                                <div style={styles.memorialNeighborhood}>
                                    {viewDeathInfo.residentLocations.neighborhood.neighborHoodCommunityName}
                                </div>
                            )}
                            <div style={styles.memorialDates}>
                                {moment(viewDeathInfo.residentBirth).format("MM/DD/YYYY")} - {moment(viewDeathInfo.datePassed).format("MM/DD/YYYY")}
                            </div>
                        </div>

                        {viewDeathInfo.deathInformation && (
                            <div style={styles.serviceInfo}>
                                <div style={styles.serviceTitle}>Memorial Service</div>
                                <div style={styles.serviceDetails}>
                                    <div style={styles.serviceLocation}>
                                        <strong>Location:</strong> {viewDeathInfo.deathInformation.serviceLocation}
                                    </div>
                                    <div style={styles.serviceDate}>
                                        <strong>When:</strong> {moment(viewDeathInfo.deathInformation.serviceDate).format('dddd, MMMM D, YYYY [at] h:mm a')}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                handleClose={() => setViewDeathInfo(undefined)}
            />
        )}

        <div style={styles.navigationFooter}>
            <div onClick={goBack} style={styles.navButton}>
                <FontAwesomeIcon icon={faArrowLeft} style={styles.navIcon} />
                <span style={styles.navText}>{backTitle()}</span>
            </div>
            <div onClick={goForward} style={styles.navButton}>
                <span style={styles.navText}>{forwardTitle()}</span>
                <FontAwesomeIcon icon={faArrowRight} style={styles.navIcon} />
            </div>
        </div>
    </>)
}

const styles = {
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
        paddingBottom: '70px', // Space for the navigation footer
    },

    // Tab navigation
    tabContainer: {
        display: 'flex',
        marginBottom: '30px',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    tab: {
        flex: 1,
        padding: '15px 10px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        borderRight: '1px solid #eee',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectedTab: {
        flex: 1,
        padding: '15px 10px',
        textAlign: 'center',
        backgroundColor: defaultColor,
        color: '#fff',
        cursor: 'pointer',
        boxShadow: '0 2px 10px rgba(233, 114, 0, 0.3)',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabIcon: {
        fontSize: '24px',
        marginBottom: '8px',
    },
    tabText: {
        fontWeight: '500',
    },

    // Month header
    monthHeader: {
        marginBottom: '25px',
        padding: '15px',
        borderRadius: '8px',
        backgroundColor: '#f5f5f5',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    monthTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#444',
    },
    monthTitleText: {
        margin: '0 0 0 10px',
        fontSize: '22px',
        fontWeight: '600',
    },

    // Cards container and grid
    cardsContainer: {
        marginTop: '20px',
    },
    cardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
        gap: '20px',
    },

    // Individual cards
    card: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
    },
    cardImageContainer: {
        padding: '15px 15px 5px',
    },
    cardContent: {
        padding: '10px 15px 20px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    cardTitle: {
        margin: '0 0 10px 0',
        fontSize: '18px',
        fontWeight: '600',
        color: defaultColor,
    },
    cardDetail: {
        fontSize: '14px',
        color: '#555',
        marginBottom: '5px',
        lineHeight: '1.4',
    },
    cardNeighborhood: {
        fontSize: '14px',
        color: '#666',
        marginTop: '5px',
        padding: '3px 8px',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        display: 'inline-block',
    },
    dateRange: {
        marginTop: '10px',
        fontSize: '14px',
        color: '#777',
        fontStyle: 'italic',
    },
    birthdayDate: {
        backgroundColor: defaultColor,
        color: 'white',
        padding: '5px 10px',
        borderRadius: '20px',
        fontSize: '14px',
        fontWeight: '600',
        marginBottom: '10px',
        display: 'inline-block',
    },

    // Empty state
    emptyState: {
        padding: '40px 20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        color: '#777',
        fontStyle: 'italic',
    },

    // Memorial popup
    memorialPopup: {
        textAlign: 'center',
        padding: '15px',
        maxWidth: '500px',
    },
    memorialImageContainer: {
        marginBottom: '15px',
    },
    memorialName: {
        color: defaultColor,
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '0 0 15px 0',
    },
    memorialDetails: {
        marginBottom: '15px',
    },
    memorialAddress: {
        fontSize: '16px',
        marginBottom: '5px',
    },
    memorialNeighborhood: {
        display: 'inline-block',
        padding: '5px 10px',
        backgroundColor: '#f5f5f5',
        borderRadius: '20px',
        fontSize: '14px',
        marginBottom: '10px',
    },
    memorialDates: {
        fontSize: '16px',
        fontWeight: '500',
        marginTop: '10px',
    },
    serviceInfo: {
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        padding: '15px',
        marginTop: '15px',
        border: `1px solid ${defaultColor}20`,
    },
    serviceTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: defaultColor,
        marginBottom: '10px',
    },
    serviceDetails: {
        textAlign: 'left',
    },
    serviceLocation: {
        marginBottom: '8px',
    },
    serviceDate: {
        marginBottom: '8px',
    },

    // Navigation footer
    navigationFooter: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '15px 20px',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 10,
    },
    navButton: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 15px',
        backgroundColor: '#f5f5f5',
        borderRadius: '25px',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
    },
    navIcon: {
        color: defaultColor,
    },
    navText: {
        margin: '0 10px',
        fontWeight: '500',
    },
};

export default Celebrations;
