import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";

function IssuuViewer(){
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [magazines, setMagazine] = useState([]);
    useEffect(()=>{
        doGet(`issuu/get/${id}`).then(res=>{
            setMagazine(res.results);
            setLoading(false);
        })
    }, [id])


    if(loading){
        return <>
            <Heading />
            <MySpinner />
        </>
    }

    return <>
        <Heading />
        <div className={'inner-container'} style={{paddingTop: '10px'}}>
            <div className="magazine-gallery-container">

                <div className="magazine-grid">
                    {magazines.map((magazine, index) => (
                        <a
                            key={index}
                            href={magazine.publicLocation}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="magazine-item"
                            aria-label={`View ${magazine.title}`}
                        >
                            <div className="magazine-cover-container">
                                <img
                                    src={magazine.cover.large.url}
                                    alt={`Cover of ${magazine.title}`}
                                    className="magazine-cover"
                                    loading="lazy"
                                />
                                <div className="magazine-overlay">
                                    <span className="view-text">Read Now</span>
                                </div>
                            </div>
                            <div className="magazine-info">
                                <h3 className="magazine-title">{magazine.title}</h3>
                            </div>
                        </a>
                    ))}
                </div>
                )
            </div>
        </div>
    </>
}
export default IssuuViewer;
