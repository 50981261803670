import {Editor} from "@tinymce/tinymce-react";
import {useState} from "react";
import {doPost, imageUploadHandler} from "../../services/rest";

function EditHeading({heading, onUpdate}) {

    const [html, setHtml] = useState(heading.headingHtml);
    const [loading, setLoading] = useState(false);

    const save = async() =>{
        setLoading(true)
        const formData = new FormData();
        formData.append("html", html)
        formData.append("id", heading.id)
        const resp = await doPost('emergency/admin/heading/update', formData)
        onUpdate(resp);
    }

    return(<div className={'inner-container'}>
        <h3>Edit Heading</h3>
        <Editor
            onEditorChange={(e) => setHtml(e)}
            licenseKey={'gpl'}
            value= {html}
            init={{
                height:500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                ],
                toolbar: 'undo redo | blocks | link table|' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | image | bullist numlist outdent indent | ' +
                    'removeformat | toggle | code',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                automatic_uploads: true,
                images_upload_handler: imageUploadHandler,
            }}
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        />
        {loading ? <div style={{
            border: '16px solid #f3f3f3',
            borderRadius: '50%',
            borderTop: '16px solid #e97200',
            width: '10px',
            height: '10px',
            marginLeft: 10,
            animation: 'spin 2s linear infinite'
        }}/> :<button onClick={save} style={{marginTop: 15}} className={'submitPost'}>Save</button>}
    </div>)
}
export default EditHeading
