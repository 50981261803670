import React from "react";

function SmallSpinner(){
    return (
        <div style={{
            border: '16px solid #f3f3f3',
            borderRadius: '50%',
            borderTop: '16px solid #e97200',
            width: '10px',
            height: '10px',
            marginLeft: 10,
            animation: 'spin 2s linear infinite'
        }}/>
    )
}
export default SmallSpinner;
