import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase/firebase";
import {useEffect, useState} from "react";
import {loadMenu} from "../services/menu";
import Sidebar from "./Sidebar";
import {useUserPreferences} from "./context/UserPreferencesProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
function Heading({title, icons}) {
    const [user, loading] = useAuthState(auth);
    const { isLegacyView} = useUserPreferences();
    const [menuItems, setMenuItems] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        if(loading) return;
        if(user){
            loadMenu().then(res => {
                setMenuItems(res)
            })
        }

    }, [user, loading]);

    const displayHeading = () => {
        if(isLegacyView){
            return <div className={'bm-burger-button'}><FontAwesomeIcon onClick={() => navigate("/")} cursor={'pointer'} icon={faHouse} /></div>
        }
        return <Sidebar items={menuItems}></Sidebar>
    }


    return(<>
        <div className={"main-heading"}>{user && displayHeading()} <div className={"heading-display"}> <h3>{title}</h3></div>
            {icons && (
                <div className={"icons-display"}>
                    {icons.map((Icon, index) => (
                        <div key={index}>
                            {Icon}
                        </div>
                    ))}
                </div>
            )}
        </div>
    </>)
}
export default Heading;
