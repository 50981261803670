import {useNavigate, useParams} from "react-router-dom";
import Heading from "../Heading";
import {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import MySpinner from "../MySpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {defaultColor} from "../../defaults/AppConfig";
import {hasAdmin} from "../../services/menu";
import {faAdd, faEdit, faPaperclip, faReorder} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import AddCategory from "./AddCategory";
import SubCategoryForm from "./SubCategoryForm";
import HR from "../../images/Human Resources - GSC Logo.GIF";
import Reorder from "./Reorder";


function Benefits() {
    const {'*': subpath} = useParams();
    const [benefits, setBenefits] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigator = useNavigate();
    const benefitAdmin = hasAdmin('ROLE_BENEFITS')
    const [showAddCategories, setShowAddCategories] = useState(false)
    const [editCategories, setEditCategories] = useState()
    const [showAddBenefit, setShowAddBenefit] = useState(false);
    const [addSubCategory, setAddSubCategory] = useState(false);
    const [editSubCategory, setEditSubCategory] = useState();
    const [header, setHeader] = useState({});
    const [showReorder, setShowReorder] = useState(false);
    const onSaveSuccess = () =>{
        if(subpath){
            console.log("subpath",subpath);
            const path = subpath.split("/");
            doGet(`benefits/category/${path[path.length -1]}`).then(resp =>{
                setBenefits(resp);
                setLoading(false)
            })
            if(path.length !== 1){
                doGet(`benefits/benefit/${path[path.length -1]}`).then(resp => setHeader(resp))
            }else{
                doGet(`benefits/single-category/${path[path.length -1]}`).then(resp => setHeader(resp))
            }

        }else{
            doGet('benefits').then(res => {
                setBenefits(res);
                setLoading(false);
            })
            doGet('benefits/header').then(resp => setHeader(resp))
        }
        setShowAddCategories(false);
        setEditSubCategory(undefined)
        setAddSubCategory(false)
    }
    useEffect(() => {
        console.log(header)
    },[header])
    useEffect(() => {
        if(editSubCategory){
            setAddSubCategory(true)
        }
    }, [editSubCategory]);
    useEffect(() => {
        onSaveSuccess()
        setEditCategories(undefined)
    }, [subpath]);

    useEffect(() => {
        if(editCategories){
            setShowAddCategories(true)
        }
    }, [editCategories]);


    const onAddClick = () => {
        if(!subpath){
            setShowAddCategories(true)
        }else{
            setShowAddBenefit(true)
        }
    }


    if(loading){
        return (<>
            <Heading title={'Benefits'} />
            <MySpinner />
        </>)
    }



    return (<>
        <Heading title={'Benefits'} icons={benefitAdmin && [<FontAwesomeIcon onClick={onAddClick} style={{cursor: 'pointer'}} icon={faAdd} />, <FontAwesomeIcon style={{paddingLeft: 15}} onClick={() => setShowReorder(true)} cursor={'pointer'} icon={faReorder} />]} />
        <div className="inner-container" style={{paddingTop: 50}}>
            {!subpath && <div style={{paddingTop: 20}}>
                <img src={HR}/>
                <div className={'reset'}>
                    <div  style={{backgroundColor: defaultColor, padding: 15}} dangerouslySetInnerHTML={{__html: header.html}}></div></div>
            </div>}
            {(header.description || header.body) && <div className={'reset'}>
                <div style={{padding: 15}}
                     dangerouslySetInnerHTML={{__html: header?.description ?? header?.body ?? '' }}></div>
            </div>}
            {benefits.map((bp, index) => (
                subpath ? <div key={index} style={{display: 'flex', flexDirection: 'row', cursor: "pointer", paddingTop: 10}}><span style={{flex: 1, textAlign: "left"}} onClick={() =>{
                    if(bp.isSubCategory) {
                        navigator(`benefits/${subpath}/${bp.id}`)
                    }else {
                        navigator(`/view-benefit/${bp.id}`)
                    }
                }}>{bp.isSubCategory ? <FontAwesomeIcon icon={faFolder} style={{color: defaultColor, fontSize: 20, paddingRight: 15}} /> :<FontAwesomeIcon icon={faPaperclip} style={{color: defaultColor, fontSize: 20, paddingRight: 15}} />}{bp.title}{!bp.show && <>&nbsp;(hidden)</>}
                </span>{(bp.isSubCategory && benefitAdmin) && <FontAwesomeIcon cursor={'pointer'} onClick={() => setEditSubCategory(bp)} icon={faEdit} />}</div> : <div key={index}
                              style={{display: 'flex', flexDirection: 'row', cursor: "pointer", paddingTop: 10}}><span onClick={() => navigator(`/benefits/${bp.id}`)} style={{flex: 1, textAlign: "left"}}><FontAwesomeIcon icon={faFolder} style={{color: defaultColor, fontSize: 20, paddingRight: 15}} /><span>{bp.name}{!bp.show && <>&nbsp;(hidden)</>}</span></span>{benefitAdmin && <FontAwesomeIcon cursor={'pointer'} onClick={() => setEditCategories(bp)} icon={faEdit} />} </div>
            ))}

        </div>
        {showAddCategories && <PopUp
            content={<AddCategory currentCategory={editCategories} onSaveSuccess={onSaveSuccess}/>}
            handleClose={()=>{
                setEditCategories(undefined)
                setShowAddCategories(false)}}
        />}
        {showAddBenefit && <PopUp
            content={<>
                <div><button onClick={() =>{
                    setAddSubCategory(true)
                    setShowAddBenefit(false)
                }} className={'submitPost'}>Add Category</button></div>
                <div onClick={() =>{
                    const path = subpath.split("/");
                    navigator("/benefits/new", {state: {category: path[path.length -1]}})
                }} style={{paddingTop: 20}}><button className={'submitPost'}>Add Benefit</button></div>
            </>}
            handleClose={()=>setShowAddBenefit(false)}
        />}
        {addSubCategory && <PopUp
            content={<SubCategoryForm currentCategory={editSubCategory} newId={subpath} onSaveSuccess={onSaveSuccess}/>}
            handleClose={()=>{
                setEditCategories(undefined)
                setAddSubCategory(false)}}
        />}
        {showReorder && <PopUp
            content={<Reorder items={benefits} subpath={subpath} onComplete={onSaveSuccess}/>}
            handleClose={()=>setShowReorder(false)}
        />}
    </>)
}
export default Benefits;
