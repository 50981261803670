import {doGet} from "../../services/rest";
import {useEffect, useState} from "react";


function SearchForUsers({ currentUsers, onSave }) {
    const [searchForUsers, setSearchForUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);



    useEffect(() => {
        setSelectedUsers(currentUsers);
    }, [currentUsers]);

    useEffect(() => {
        console.log(selectedUsers);
    }, [selectedUsers]);

    // Add a state to track the search query
    const [searchQuery, setSearchQuery] = useState('');

    // Handle input change
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Handle key press
    const onKeyDown = async event => {
        if (event.key === 'Enter' && searchQuery.length > 2) {
            performSearch(event);
        }
    };

    const addSelectedUsers = (users) => {
        setSelectedUsers([...selectedUsers, users]);
    }

    const removeUser = (user) => {
        setSelectedUsers(prevState => prevState.filter(user1 => user1.id !== user.id));
    }

    // Function to perform search
    const performSearch = async (event) => {
        if (searchQuery.length > 2) {
            try {
                const resp = await doGet(`app-admin/menu-item/search?q=${event.target.value}`);


                // Handle different response structures (resp or resp.data)
                const responseData = Array.isArray(resp) ? resp : (resp.data || []);

                // Filter out users that are already in currentUsers
                // Assuming both currentUsers and response data have an id property
                const currentUserIds = selectedUsers.map(user => user.id);
                const filteredUsers = responseData.filter(user => !currentUserIds.includes(user.id));

                setSearchForUsers(filteredUsers);
            } catch (error) {
                console.error('Error searching for users:', error);
            }
        }
    };

    return (
        <div className="inner-container">
            <div className="search-input-container">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDown}
                    className="fancy-input"
                    placeholder="Search for users"
                />

            </div>

            {/* Display selected users in a row */}
            {selectedUsers && selectedUsers.length > 0 && (
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                    padding: '10px 0',
                    borderBottom: '1px solid #eee',
                    marginBottom: '10px'
                }}>
                    {selectedUsers.map(user => (
                        <div
                            onClick={() => removeUser(user)}
                            key={user.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f0f0f0',
                                padding: '4px 10px',
                                borderRadius: '16px',
                                fontSize: '0.9em'
                            }}
                        >
                            <span style={{ marginRight: '5px' }}>
                                {user.displayName || user.name || user.username || user.email || 'Unknown User'}
                            </span>
                        </div>
                    ))}
                </div>
            )}

            {/* Display search results */}
            {searchForUsers.length > 0 ? (
                <div style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                    margin: '10px 0',
                    padding: '10px',
                    backgroundColor: '#fff'
                }}>
                    <h4 style={{
                        marginTop: '0',
                        borderBottom: '1px solid #eee',
                        paddingBottom: '8px',
                        color: '#333'
                    }}>Search Results:</h4>
                    {searchForUsers.map(user => (
                        <div
                            onClick={() => addSelectedUsers(user)}
                            id={user.id}
                            key={user.id || Math.random()}
                            style={{
                                padding: '10px',
                                borderBottom: '1px solid #f5f5f5',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                transition: 'background-color 0.2s',
                                borderRadius: '3px',
                                margin: '3px 0'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                        >
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ddd',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '10px',
                                color: '#666',
                                fontWeight: 'bold'
                            }}>
                                {(user.displayName || user.name || user.username || '?').charAt(0).toUpperCase()}
                            </div>
                            <div>
                                <div style={{ fontWeight: 'bold' }}>
                                    {user.displayName || user.name || user.username || user.email || 'Unknown User'}
                                </div>
                                {user.email && <div style={{ fontSize: '0.8em', color: '#666' }}>{user.email}</div>}
                            </div>
                        </div>
                    ))}
                </div>
            ) : searchQuery.length > 2 && (
                <></>
            )}
            <button
                onClick={() => onSave(selectedUsers)}
                className="search-button"
                disabled={searchQuery.length < 3}
            >
                Save
            </button>
        </div>
    );
}

export default SearchForUsers;
