import ResidentImages from "./ResidentImages";
import {formatFirstNameLastName} from "./NameFormatters";
import {defaultColor} from "../../defaults/AppConfig";
import {useEffect, useState} from "react";
import {hasAdmin} from "../../services/menu";
import {doGet, doPostBody} from "../../services/rest";
import MySpinner from "../MySpinner";

function ViewResident({resident}) {
    const [maintenanceInfo, setMaintenanceInfo] = useState()
    const [elopementAdmin, setElopementAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [elopementLocations, setElopementLocations] = useState([]);
    const [showElopement, setShowElopement] = useState(false);
    const [selectedResidents, setSelectedResidents] = useState();
    const [selectedLocations, setSelectedLocations] = useState();
    const [editMaintenance, setEditMaintenance] = useState(false)

    useEffect(() =>{
        if(hasAdmin("ROLE_MAINTENANCE")){
            const residents = resident.residentsNew.sort((a, b) =>
                a.residentGender === 'male' ? -1 : 1,
            );
            const residentNumber = residents[0].residentNumber;

            doGet("directory/maintenance/ok-enter/"+residentNumber).then(res => setMaintenanceInfo(res)).catch(err =>{
                setMaintenanceInfo({okenter: 'no', resid: residentNumber})
            });
        }
    }, [])

    useEffect(() => {
        setElopementAdmin(hasAdmin('ROLE_ELOPEMENT'))
    }, [])

    const sendElopementAlert = async () => {
        setIsLoading(true)
        const data = await doGet("directory/elopement-alert/locations")
        setElopementLocations(data)
        setIsLoading(false)
        setShowElopement(true)
    }

    const checkBoxChecked = (uid) => {
        const updatedResidents = selectedResidents ? [...selectedResidents] : [];
        return updatedResidents.some(resident => resident.uid === uid);
    };

    const toggleResident = (resident) => {
        const updatedResidents = selectedResidents ? [...selectedResidents] : [];
        const index = updatedResidents.findIndex(r => r.id === resident.id);
        if (index > -1) {
            updatedResidents.splice(index, 1);
        } else {
            updatedResidents.push(resident);
        }
        setSelectedResidents(updatedResidents);
    };

    const toggleLocations = (location) => {
        const locations = selectedLocations ? [...selectedLocations] : [];
        const index = locations.findIndex(r => r.id === location.id);
        if (index > -1) {
            locations.splice(index, 1);
        } else {
            locations.push(location);
        }
        setSelectedLocations(locations);
    };

    const locationSelected = (id) => {
        const locations = selectedLocations ? [...selectedLocations] : [];
        return locations.some(resident => resident.id === id);
    };

    const sendTheAlert = async () =>{
        if (
            resident.residentsNew.length > 1 &&
            (!selectedResidents || selectedResidents?.length === 0)
        ) {
            window.alert(
                'Since there is more then one resident you must make at least one selection to send the alert.',
            );
            return;
        }
        if (!selectedLocations || selectedLocations?.length === 0) {
            window.alert(
                'You must make at least one location selection to send the alert.',
            );
            return;
        }
        const resp = await doPostBody('directory/elopement-alert/send-alert', {
            locations: selectedLocations,
            residents: !selectedResidents ? resident.residentsNew : selectedResidents,
        });
        if (resp) {
            window.alert('Elopement alert sent successfully.');
        } else {
            window.alert('Failed to send elopement alert.');
        }
        setSelectedLocations(undefined);
        setSelectedResidents(undefined);
        setShowElopement(false);
    }

    if(isLoading){
        return(<><MySpinner /></>)
    }

    const chedkboxChange = () =>{
        setMaintenanceInfo(prevState => {
            const newMaintenanceInfo = { ...prevState };
            newMaintenanceInfo.okenter = newMaintenanceInfo.okenter === 'yes' ? 'no' : 'yes';
            return newMaintenanceInfo;
        });
    }

    const updateNotes = event => {
        setMaintenanceInfo(prevState => {
            const newMaintenanceInfo = { ...prevState };
            newMaintenanceInfo.notes = event.target.value;
            return newMaintenanceInfo;
        });
    }

    const updateMaintenance = async () => {
        setIsLoading(true)
        const update = await doPostBody('directory/maintenance/update', maintenanceInfo);
        setMaintenanceInfo(update)
        setIsLoading(false)
        setEditMaintenance(false)
    }

    if(editMaintenance){
        return(
            <div style={style.editMaintenanceContainer}>
                <h2 style={style.editMaintenanceTitle}>Edit Maintenance Info for {formatFirstNameLastName(resident.residentsNew)}</h2>

                <div style={style.checkboxContainer}>
                    <div className="survey-button-container" style={style.checkboxWrapper}>
                        <input
                            className="survey-radio"
                            type="checkbox"
                            id={maintenanceInfo.resid}
                            checked={maintenanceInfo.okenter === 'yes'}
                            onClick={chedkboxChange}
                        />
                        <label
                            className="checkbox-label"
                            htmlFor={maintenanceInfo.resid}
                            style={style.checkboxLabel}
                        >
                            Resident has indicated it is ok to enter.
                        </label>
                    </div>
                </div>

                <div style={style.notesContainer}>
                    <label style={style.notesLabel} htmlFor="notes">Notes: </label>
                    <input
                        style={style.notesInput}
                        type="text"
                        id="notes"
                        value={maintenanceInfo.notes || ''}
                        onChange={updateNotes}
                    />
                </div>

                <div style={style.buttonContainer}>
                    <button
                        onClick={() => setEditMaintenance(false)}
                        style={style.cancelButton}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={updateMaintenance}
                        style={style.updateButton}
                    >
                        Update
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div style={style.container}>
            {elopementAdmin && (
                <div style={style.elopementButtonContainer}>
                    <button
                        onClick={sendElopementAlert}
                        style={style.elopementButton}
                    >
                        Send Elopement Alert
                    </button>
                </div>
            )}

            <div style={style.profileHeader}>
                <div style={style.imageWrapper}>
                    <ResidentImages resident={resident} thumb={false} />
                </div>

                <div style={style.basicInfo}>
                    <h2 style={style.name}>{formatFirstNameLastName(resident.residentsNew)}</h2>
                    <div style={style.addressContainer}>
                        <div style={style.address}>
                            <span style={style.addressLabel}>Address:</span>
                            <span style={style.addressValue}>
                                {resident.homeNumber} {resident.address}
                            </span>
                        </div>
                        <div style={style.neighborhood}>
                            <span style={style.neighborhoodLabel}>Location:</span>
                            <span style={style.neighborhoodValue}>
                                {resident.neighborhood.neighborHoodCommunityName}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div style={style.detailsContainer}>
                <h3 style={style.sectionTitle}>Contact Information</h3>

                <div style={style.contactGrid}>
                    {resident.homePhone && (
                        <div style={style.contactItem}>
                            <div style={style.contactLabel}>Home Phone</div>
                            <div style={style.contactValue}>
                                <a style={style.linkStyle} href={`tel:${resident.homePhone}`}>
                                    {resident.homePhone}
                                </a>
                            </div>
                        </div>
                    )}

                    {resident.homeEmail && (
                        <div style={style.contactItem}>
                            <div style={style.contactLabel}>Home Email</div>
                            <div style={style.contactValue}>
                                <a style={style.linkStyle} href={`mailto:${resident.homeEmail}`}>
                                    {resident.homeEmail}
                                </a>
                            </div>
                        </div>
                    )}

                    {resident.residentsNew
                        .sort((a, b) => (a.residentGender === 'male' ? -1 : 1))
                        .map((resident, index) =>
                            resident.cellNumber ? (
                                <div key={`cell-${index}`} style={style.contactItem}>
                                    <div style={style.contactLabel}>{resident.firstName}'s Cell Phone</div>
                                    <div style={style.contactValue}>
                                        <a style={style.linkStyle} href={`tel:${resident.cellNumber}`}>
                                            {resident.cellNumber}
                                        </a>
                                    </div>
                                </div>
                            ) : null
                        )
                    }

                    {resident.residentsNew
                        .sort((a, b) => (a.residentGender === 'male' ? -1 : 1))
                        .map((resident, index) =>
                            resident.emailAddress ? (
                                <div key={`email-${index}`} style={style.contactItem}>
                                    <div style={style.contactLabel}>{resident.firstName}'s Email</div>
                                    <div style={style.contactValue}>
                                        <a style={style.linkStyle} href={`mailto:${resident.emailAddress}`}>
                                            {resident.emailAddress}
                                        </a>
                                    </div>
                                </div>
                            ) : null
                        )
                    }
                </div>

                {maintenanceInfo && (
                    <div style={style.maintenanceContainer}>
                        <div
                            style={style.maintenanceHeader}
                            onClick={() => setEditMaintenance(true)}
                        >
                            <h3 style={style.maintenanceTitle}>Maintenance Information</h3>
                            <div style={style.editIcon}>✏️</div>
                        </div>

                        <div style={style.maintenanceStatus}>
                            {maintenanceInfo.okenter.toLowerCase() === 'yes'
                                ? <div style={style.statusOk}>Resident has indicated it is OK to enter.</div>
                                : <div style={style.statusNotOk}>Resident has NOT given permission to enter when they are not home.</div>
                            }
                        </div>

                        {maintenanceInfo.notes && (
                            <div style={style.maintenanceNotes}>
                                <div style={style.maintenanceNotesLabel}>Notes:</div>
                                <div style={style.notesText}>{maintenanceInfo.notes}</div>
                            </div>
                        )}
                    </div>
                )}

                {showElopement && (
                    <div style={style.elopementContainer}>
                        <h3 style={style.elopementTitle}>Elopement Alert Settings</h3>

                        {resident.residentsNew.length > 1 && (
                            <div style={style.elopementResidentsSection}>
                                <div style={style.elopementSectionLabel}>
                                    Select Resident(s) to send the alert for:
                                </div>

                                <div style={style.checkboxGrid}>
                                    {resident.residentsNew.map((resident, idx) => (
                                        <div key={idx} className="survey-button-container" style={style.elopementCheckbox}>
                                            <input
                                                className="survey-radio"
                                                type="checkbox"
                                                id={resident.id}
                                                name={resident.id}
                                                value={resident.id}
                                                onClick={() => toggleResident(resident)}
                                                checked={checkBoxChecked(resident.uid)}
                                            />
                                            <label
                                                className="checkbox-label"
                                                htmlFor={resident.id}
                                                style={style.checkboxLabel}
                                            >
                                                {resident.firstName} {resident.lastName}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div style={style.elopementLocationsSection}>
                            <div style={style.elopementSectionLabel}>
                                Select which locations to send the alert to:
                            </div>

                            <div style={style.checkboxGrid}>
                                {elopementLocations.map((location, idx) => (
                                    <div key={idx} className="survey-button-container" style={style.elopementCheckbox}>
                                        <input
                                            className="survey-radio"
                                            type="checkbox"
                                            id={'l'+location.id}
                                            name={location.id}
                                            value={location.id}
                                            onClick={() => toggleLocations(location)}
                                            checked={locationSelected(location.id)}
                                        />
                                        <label
                                            className="checkbox-label"
                                            htmlFor={'l'+location.id}
                                            style={style.checkboxLabel}
                                        >
                                            {location.locationName}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <button
                            onClick={sendTheAlert}
                            style={style.sendAlertButton}
                        >
                            Send Alert
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

const style = {
    // Main container
    container: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        maxWidth: '600px',
        margin: '0 auto',
        width: '100%',
        boxSizing: 'border-box',
    },

    // Profile header section
    profileHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '25px',
        flexWrap: 'wrap',
        borderBottom: '1px solid #f0f0f0',
        paddingBottom: '20px',
    },
    imageWrapper: {
        marginRight: '25px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'center',
    },
    basicInfo: {
        flex: 1,
        minWidth: '200px',
    },
    name: {
        color: defaultColor,
        fontWeight: 'bold',
        fontSize: '28px',
        margin: '0 0 15px 0',
    },

    // Address section
    addressContainer: {
        marginBottom: '10px',
    },
    address: {
        marginBottom: '8px',
        display: 'flex',
        flexDirection: 'column',
    },
    addressLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#555',
        marginBottom: '3px',
    },
    addressValue: {
        fontSize: '16px',
        color: '#333',
    },
    neighborhood: {
        display: 'flex',
        flexDirection: 'column',
    },
    neighborhoodLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#555',
        marginBottom: '3px',
    },
    neighborhoodValue: {
        fontSize: '16px',
        color: '#333',
        fontWeight: '500',
    },

    // Details container
    detailsContainer: {
        width: '100%',
    },
    sectionTitle: {
        fontSize: '18px',
        color: '#333',
        marginBottom: '15px',
        paddingBottom: '8px',
        borderBottom: '2px solid #f0f0f0',
        position: 'relative',
    },

    // Contact grid
    contactGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
        gap: '15px',
        marginBottom: '25px',
    },
    contactItem: {
        marginBottom: '10px',
    },
    contactLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#555',
        marginBottom: '5px',
    },
    contactValue: {
        fontSize: '16px',
    },
    linkStyle: {
        textDecoration: 'none',
        color: defaultColor,
        transition: 'color 0.2s ease',
        borderBottom: '1px dashed #f0f0f0',
        paddingBottom: '2px',
    },

    // Maintenance section
    maintenanceContainer: {
        background: '#f9f9f9',
        borderRadius: '10px',
        padding: '15px',
        marginBottom: '20px',
        borderLeft: `4px solid ${defaultColor}`,
    },
    maintenanceHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        cursor: 'pointer',
        padding: '5px 0',
        borderBottom: '1px solid #eee',
    },
    maintenanceTitle: {
        margin: '0',
        fontSize: '18px',
        color: defaultColor,
        fontWeight: '600',
    },
    editIcon: {
        fontSize: '16px',
        opacity: '0.7',
        transition: 'opacity 0.2s ease',
    },
    maintenanceStatus: {
        margin: '10px 0',
        padding: '8px 12px',
        borderRadius: '5px',
    },
    statusOk: {
        backgroundColor: '#e6f7e6',
        color: '#2e7d32',
        padding: '8px 12px',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: '500',
    },
    statusNotOk: {
        backgroundColor: '#fff0f0',
        color: '#c62828',
        padding: '8px 12px',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: '500',
    },
    maintenanceNotes: {
        marginTop: '10px',
    },
    maintenanceNotesLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        marginBottom: '5px',
        color: '#555',
    },
    notesText: {
        fontSize: '14px',
        color: '#555',
        lineHeight: '1.4',
        padding: '8px 0',
    },

    // Edit maintenance screen
    editMaintenanceContainer: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '12px',
        maxWidth: '500px',
        margin: '0 auto',
    },
    editMaintenanceTitle: {
        color: defaultColor,
        fontSize: '22px',
        marginTop: '0',
        marginBottom: '20px',
        textAlign: 'center',
    },
    checkboxContainer: {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'center',
    },
    checkboxWrapper: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
    },
    checkboxLabel: {
        fontSize: '15px',
    },
    notesContainer: {
        margin: '20px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    notesLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        marginBottom: '8px',
        color: '#555',
    },
    notesInput: {
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        fontSize: '15px',
        backgroundColor: '#f9f9f9',
        outline: 'none',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '25px',
    },
    cancelButton: {
        background: '#f1f1f1',
        borderColor: '#ddd',
        color: '#555',
        padding: '8px 16px',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '14px',
        border: 'none',
        fontWeight: '500',
        transition: 'all 0.2s ease',
    },
    updateButton: {
        background: defaultColor,
        borderColor: defaultColor,
        color: "#fff",
        padding: '8px 16px',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '14px',
        border: 'none',
        fontWeight: '500',
        transition: 'all 0.2s ease',
    },

    // Elopement section
    elopementButtonContainer: {
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'center',
    },
    elopementButton: {
        background: defaultColor,
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        padding: '8px 16px',
        fontSize: '14px',
        cursor: 'pointer',
        fontWeight: '500',
        transition: 'all 0.2s ease',
    },
    elopementContainer: {
        background: '#f9f9f9',
        borderRadius: '10px',
        padding: '15px',
        marginTop: '20px',
        borderLeft: `4px solid ${defaultColor}`,
    },
    elopementTitle: {
        color: defaultColor,
        fontSize: '18px',
        marginTop: '0',
        marginBottom: '15px',
        borderBottom: '1px solid #eee',
        paddingBottom: '8px',
    },
    elopementResidentsSection: {
        marginBottom: '20px',
    },
    elopementLocationsSection: {
        marginBottom: '20px',
    },
    elopementSectionLabel: {
        fontWeight: 'bold',
        fontSize: '15px',
        marginBottom: '10px',
        color: '#444',
    },
    checkboxGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '10px',
    },
    elopementCheckbox: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
    },
    sendAlertButton: {
        background: defaultColor,
        color: '#fff',
        border: 'none',
        width: '100%',
        padding: '10px',
        borderRadius: '8px',
        fontSize: '15px',
        fontWeight: '500',
        cursor: 'pointer',
        marginTop: '10px',
        transition: 'all 0.2s ease',
    },

    // Preserving original styles that might be used elsewhere
    divPadding: {
        paddingTop: '10px',
    },
    defaultContainer: {
        alignItems: 'center',
    },
    imageContainer: {
        width: '80%',
        flexDirection: 'row',
    },
    informationContainer: {
        width: '80%',
        paddingTop: 10,
        textAlign: 'left'
    },
    multiText: {
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
    },
    textBold: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textNotBold: {
        fontSize: 16,
    },
    elopementAlertContainer: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    paddingTop: {
        paddingTop: 10,
    }
};

export default ViewResident;
