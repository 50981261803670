import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet, doPost, doPostBody, mainUrl} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {availableIcons} from "../Sidebar";
import PopUp from "../PopUp";
import MyCheckBox from "../MyCheckBox";
import SearchForUsers from "./SearchForUsers";

function MenuItemsAdmin() {
    const {item} = useParams()
    const [loading, setLoading] = useState(true);
    const [menuItems, setMenuItems] = useState({});
    const [menuItemType, setMenuItemType] = useState([]);
    const [showIconPicker, setShowIconPicker] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {parentItems} = location.state || {};
    const [appLocations, setAppLocations] = useState([]);
    const [currentAdminUser, setCurrentAdminUser] = useState([]);
    const [searchforUsers, setSearchforUsers] = useState(false);
    const [prevPath, setPrevPath] = useState(location.pathname);

    useEffect(() => {
        doGet('app-admin/menu-item-types').then(resp => {
            setLoading(false);
            setMenuItemType(resp)});
    }, []);

    useEffect(() => {
        if(parentItems){
            doGet('app-admin').then(resp =>{
                setAppLocations(resp.appLocations)
            })
        }
    }, [parentItems]);

    useEffect(() => {
        if(menuItems?.menuItemTypes?.id === 23 && item){
            doGet(`app-admin/menu-item/html/get-users/${item}`).then(resp => {
                console.log(resp);
                setCurrentAdminUser(resp);
            })
        }else{
            setCurrentAdminUser([])
        }
    },[menuItems?.menuItemTypes, item])

    // Reset form when location path changes
    useEffect(() => {
        if (location.pathname !== prevPath) {
            setPrevPath(location.pathname);
            // Clear form when navigation happens
            if (!item) {
                setMenuItems({});
            }
        }
    }, [location.pathname, item, prevPath]);

    // Load item data or reset form
    useEffect(() => {
        if(item){
            doGet(`app-admin/menu-item/${item}`).then(resp => setMenuItems(resp));
        } else {
            setMenuItems({});
        }
    }, [item]);

    const onChange = event => {
        setMenuItems(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const updateNested =  event => {
        const { name, value } = event.target;
        setMenuItems(prevState => ({
            ...prevState,
            issuuConnection: {
                ...(prevState.issuuConnection || {}),  // Keep existing properties
                [name]: value  // Update just the changed property
            }
        }));
    }

    const onSelectChange = event =>{
        const item =  menuItemType.find(obj => obj.id === parseInt(event.target.value));
        setMenuItems(prevState => ({
            ...prevState,
            menuItemTypes: item
        }))
    }

    const isChecked = item => {
        return menuItems?.subMenuItems?.some(subMenuItem => subMenuItem.id === item.id);
    }

    const onCheckBoxChecked = (location) => {
        if (menuItems?.subMenuItems?.some(item => item.id === location.id)) {
            // If location is already in the list, remove it
            setMenuItems(prevState => ({
                ...prevState,
                subMenuItems: prevState.subMenuItems.filter(item => item.id !== location.id)
            }));
        } else {
            // If location is not in the list, add it
            setMenuItems(prevState => ({
                ...prevState,
                subMenuItems: Array.isArray(prevState.subMenuItems)
                    ? [...prevState.subMenuItems, location]
                    : [location]
            }));
        }
    };

    const save = async () => {
        if(!menuItems.title){
            window.alert("A title is required")
            return;
        }
        if(!menuItems.menuItemTypes){
            window.alert("Menu item type is required")
            return;
        }
        if(menuItems.menuItemTypes.id === 8 && menuItems.singleLink && !menuItems.singleUrl){
            window.alert("You must specify a URL for a single URL repository.")
            return;
        }

        setLoading(true);
        if(parentItems){
            menuItems.parent = parentItems;
        }

        const resp = await doPostBody(`app-admin/menu-item/save`, menuItems);

        if(resp.id){
            window.alert("Your item has been saved")
            setMenuItems(resp)
            setLoading(false)
        }else{
            window.alert("Something went wrong you menu item was not saved.")
            setLoading(false)
        }
    }

    const deleteSubItem = async (item2) => {
        if(window.confirm("Are you sure you want to delete this sub item?  If this is a sub menu it will delete all of its children")){
            setLoading(true);
            try{
                await doPostBody(`app-admin/menu-item/delete-sub-item`, item2);
            }catch(e){}
            const resp = await doGet(`app-admin/menu-item/${item}`);
            console.log(resp)
            setMenuItems(resp);
            setLoading(false);
        }
    }
    const onUserSave =  async (users) => {
        setSearchforUsers(false)
        setLoading(true);
        const resp = await doPostBody(`app-admin/menu-item/save-users-html/${item}`, users);
        setCurrentAdminUser(resp)
        setLoading(false);
    }

    // Function to handle adding a new menu item
    const handleAddClick = () => {
        // Clear form data first
        setMenuItems({});
        // Then navigate
        navigate('/app-admin/menu-items', {state: {parentItems: menuItems}});
    };

    if(loading){
        return (<>
            <Heading title={'Menu Item'} />
            <MySpinner />
        </>)
    }

    const uploadFile = file =>{
        const formData = new FormData();
        formData.append("file", file.target.files[0])
        doPost('app-admin/menu-item/upload-icon', formData).then(data => {
            setMenuItems(prevState => ({
                ...prevState,
                legacyIcon: data.filename,
            }))
        })
    }

    const removeFile = event =>{
        event.preventDefault();
        setMenuItems(prevState => ({
            ...prevState,
            legacyIcon: undefined,
        }))
    }

    return(<>
        <Heading title={'Menu Item'} />
        {parentItems && <p>Parent menu item: {parentItems.title}</p>}
        <div className={'inner-container'} style={{padding: 50}}>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Title</label>
            <input type={'text'} name={'title'} onChange={onChange} className={'search-input'}
                   placeholder={'Title'} value={menuItems.title || ''}/>
            <label style={{display: "flex", paddingBottom: 5, paddxingLeft: 5}}>Description</label>
            <input style={{marginTop: 10, marginBottom: 10}} type={'text'} name={'description'}
                   onChange={onChange} className={'search-input'}
                   placeholder={'Description'} value={menuItems.description || ''}/>
            <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Menu Item Type</label>
            <select name={'menuItemTypes'} className={'search-input'} onChange={onSelectChange} value={menuItems?.menuItemTypes?.id || ''}>
                <option value=""></option>
                {menuItemType.map(menuItemType => (
                    <option key={menuItemType.id} value={menuItemType.id}>
                        {menuItemType.name}
                    </option>
                ))}
            </select>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>Select Icon: <FontAwesomeIcon
                style={{marginLeft: 10}} onClick={() => setShowIconPicker(true)} cursor={'pointer'}
                icon={availableIcons[menuItems.icon ?? 'fa-face-smile']}/></div>
            {/* Legacy Icon Upload Section */}
            <div style={{
                margin: '20px 0',
                padding: '15px',
                borderRadius: '8px',
                backgroundColor: '#f8f9fa',
                border: '1px solid #e2e8f0'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                }}>
                    <label style={{
                        fontWeight: '500',
                        marginBottom: '8px',
                        fontSize: '16px'
                    }}>
                        Upload Legacy Icon
                    </label>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <label style={{
                            padding: '8px 16px',
                            backgroundColor: '#4a90e2',
                            color: 'white',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontWeight: '500',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                        }}>
                            Choose File
                            <input
                                type="file"
                                accept="image/*"
                                onChange={uploadFile}
                                style={{
                                    display: 'none'
                                }}
                            />
                        </label>
                        <span style={{
                            color: '#718096',
                            fontSize: '14px'
                        }}>
        {menuItems.legacyIcon ? menuItems.legacyIcon : 'No file selected'}
      </span>
                    </div>

                    {menuItems.legacyIcon && (
                        <div style={{
                            marginTop: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px'
                        }}>
                            <div style={{
                                width: '125px',
                                height: '125px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #e2e8f0',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                backgroundColor: 'white'
                            }}>
                                <img
                                    src={mainUrl + 'menu/icon/' + menuItems.legacyIcon}
                                    alt="Icon preview"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain'
                                    }}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px'
                            }}>
          <span style={{ fontSize: '14px', color: '#4a5568' }}>
            Current icon: <strong>{menuItems.legacyIcon}</strong>
          </span>
                                <a
                                    onClick={removeFile}
                                    href="#"
                                    style={{
                                        color: '#e53e3e',
                                        textDecoration: 'none',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px'
                                    }}
                                >
                                    <span style={{ fontSize: '16px' }}>×</span> Remove image
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <label style={{display: "flex", paddingBottom: 5, paddxingLeft: 5}}>Order Priority (0 highest)</label>
            <input style={{marginTop: 10, marginBottom: 10}} type={'number'} name={'order'}
                   onChange={onChange} className={'search-input'}
                   placeholder={'Priority'} value={menuItems.order || ''}/>
            {menuItems?.menuItemTypes?.id === 8 && <MyCheckBox id={'single-link'} onChange={() => setMenuItems(prevState => ({...prevState, singleLink: !prevState.singleLink}))} checked={menuItems.singleLink || false} label={'Single Link Repository'} />}
            {menuItems?.menuItemTypes?.id === 8 && menuItems.singleLink && <>
                <label style={{display: "flex", paddingBottom: 5, paddxingLeft: 5}}>Enter the URL</label>
                <input style={{marginTop: 10, marginBottom: 10}} type={'text'} name={'singleUrl'}
                       onChange={onChange} className={'search-input'}
                       placeholder={'Single Url'}
                       value={menuItems.singleUrl || ''}/>
            </>
            }
            { parentItems && appLocations && <>
                <p>To filter sub locations select the locations below, otherwise it will follow the security of its parent.</p>

                {appLocations.map(location =>(
                    <MyCheckBox id={location.id} key={location.id} label={location.locationName} onChange={() => onCheckBoxChecked(location)} checked={isChecked(location)}/>
                ))}

            </>}
            <button onClick={save} style={{marginBottom: 10}} className={'submitPost'}>Save</button>
            {menuItems?.menuItemTypes?.id === 23 && item &&
                <div className="menu-container" style={{paddingTop: 10}}>
                    <h3>Admin Users</h3>
                    <button style={{marginBottom: 10}} onClick={() => setSearchforUsers(true)} className={'submitPost'}>Manage Users</button>
                    <ul className="fancyList menu-list">
                        {currentAdminUser?.map(item => (
                            <li key={item.id} className="menu-item">
                                <div className="menu-item-content">
                                    <span className="menu-item-title">{item.displayName}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            }
            {menuItems?.menuItemTypes?.id === 24 && <>
                <div style={{marginTop: 20}}>
                    <h3>Issuu Configuration</h3>
                    <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5}}>Bearer</label>
                    <input
                        type={'text'}
                        name={'bearer'}
                        onChange={updateNested}
                        className={'search-input'}
                        placeholder={'Bearer'}
                        value={menuItems.issuuConnection.bearer || ''}
                    />

                    <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5, marginTop: 10}}>Size</label>
                    <input
                        type={'number'}
                        name={'size'}
                        onChange={updateNested}
                        className={'search-input'}
                        placeholder={'Size'}
                        value={menuItems.issuuConnection.size || ''}
                    />

                    <label style={{display: "flex", paddingBottom: 5, paddingLeft: 5, marginTop: 10}}>Filter By</label>
                    <input
                        type={'text'}
                        name={'filterBy'}
                        onChange={updateNested}
                        className={'search-input'}
                        placeholder={'Filter By'}
                        value={menuItems.issuuConnection.filterBy || ''}
                    />
                </div>
            </>}
            {menuItems?.menuItemTypes?.id === 22 && menuItems.children && <>
                <div className="menu-container" style={{paddingTop: 10}}>
                    <h3>Sub Menu Items</h3>
                    <button onClick={handleAddClick} style={{marginBottom: 10}} className={'submitPost'}>Add</button>
                    <ul className="fancyList menu-list">
                        {menuItems.children.map(item => (
                            <li key={item.id} className="menu-item">
                                <div className="menu-item-content">
                                    <span className="menu-item-title">{item.title}</span>

                                    <div className="menu-item-actions">
                                        <button
                                            className="menu-action-button edit-button"
                                            onClick={() => navigate(`/app-admin/menu-items/${item.id}`, {state: {parentItems: menuItems}})}>
                                            Edit
                                        </button>

                                        <button
                                            className="menu-action-button delete-button"
                                            onClick={() => deleteSubItem(item)}
                                            aria-label={`Delete ${item.title}`}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </>}
        </div>
        {
            showIconPicker && <PopUp
                content={<>
                    <div className={'inner-container'}>
                        <h3>Select your icon</h3>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {Object.entries(availableIcons).map(([key, value]) => (
                                <FontAwesomeIcon
                                    key={key}
                                    title={key}  // Optionally set key as a title for easy identification
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        setMenuItems(prevState => ({
                                            ...prevState,
                                            icon: key
                                        }))
                                        setShowIconPicker(false)
                                    }}
                                    cursor='pointer'
                                    icon={value}
                                />
                            ))}
                        </div>
                    </div>
                </>}
                handleClose={() => setShowIconPicker(false)}
            />}
        {searchforUsers && <PopUp
            content={<SearchForUsers currentUsers={currentAdminUser} onSave={users => onUserSave(users)} />}
            handleClose={() => setSearchforUsers(false)}
        />}
    </>)
}

export default MenuItemsAdmin;
