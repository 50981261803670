import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import {serviceUrl} from "../../defaults/AppConfig";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import moment from "moment";

function MyItems() {
    const [loading, setLoading] = useState(true);
    const [marketplaceItems, setMarketplaceItems] = useState([]);
    const navigation = useNavigate();
    const [currentImageIndexes, setCurrentImageIndexes] = useState({});
    useEffect(() => {
        doGet('marketplace/mine').then(data => {
            setMarketplaceItems(data);
        }).then(()=>{
            setLoading(false);
        })
    }, [])

    const handleNextImage = (itemId) => {
        setCurrentImageIndexes(prev => ({
            ...prev,
            [itemId]: ((prev[itemId] || 0) + 1) % marketplaceItems.content.find(item => item.id === itemId).images.length
        }));
    };

    const handlePrevImage = (itemId) => {
        setCurrentImageIndexes(prev => ({
            ...prev,
            [itemId]: ((prev[itemId] || 0) - 1 + marketplaceItems.content.find(item => item.id === itemId).images.length) %
            marketplaceItems.content.find(item => item.id === itemId).images.length
        }));
    };


    if (loading) {
        return (
            <>
                <Heading title={'My Items'}/>
                <MySpinner />
            </>
        );
    }

    const getStatus = (item) => {
        if(item.deleted){
            return "Removed";
        }
        if(moment().diff(moment(item.postedDate), 'days') > 40){
            return "Expired";
        }

        return "Active";
    }

    return (<>
        <Heading title={'My Items'}/>
            <div className={'inner-container'}>
            <div className="grid">
                {marketplaceItems.map((item) => (
                    <div style={{width: 200}}
                         key={item.id}
                         className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
                         onClick={() => navigation(`/marketplace/${item.id}`)}
                    >
                        <div className="relative w-full pt-[100%]">
                            {item.images && item.images.length > 0 && (
                                <>
                                    <img
                                        src={serviceUrl+`marketplace/images/thumbnail/${item.images[currentImageIndexes[item.id] || 0]}`}
                                        alt={item.title}
                                        className="absolute top-0 left-0 w-full h-full object-cover"
                                    />
                                    {item.images.length > 1 && (
                                        <>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handlePrevImage(item.id);
                                                }}
                                                className="absolute left-2 top-1/2 transform bg-black/50 text-white rounded-full p-2"
                                            >
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleNextImage(item.id);
                                                }}
                                                className="absolute right-2 top-1/2 transform bg-black/50 text-white rounded-full p-2"
                                            >
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="p-4">
                            <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                            <p className="text-xl font-bold text-green-600 mb-2">
                                ${item.price.toLocaleString('en-US', {minimumFractionDigits: 2})}
                            </p>
                            <div className="text-sm text-gray-600">
                                {item.contactPhoneNumber && (
                                    <p className="mb-1">📞 {item.contactPhoneNumber}</p>
                                )}
                                {item.contactEmail && (
                                    <p>✉️ {item.contactEmail}</p>
                                )}
                                <p><span>Status: {getStatus(item)}</span></p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        </>
        )
        }
        export default MyItems;
